const firebaseConfigDev = {
  apiKey: "AIzaSyB4Fif0W7gHfYkF88NiFeu5H-hGoLnTbVQ",
  authDomain: "ezbyte-admin-panel.firebaseapp.com",
  projectId: "ezbyte-admin-panel",
  storageBucket: "ezbyte-admin-panel.appspot.com",
  messagingSenderId: "759026620490",
  appId: "1:759026620490:web:079818be12858050672eb3",
};

export default firebaseConfigDev;
