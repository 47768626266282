export const COPY_API_URL = process.env.REACT_APP_COPY_API_URL;
// export const COPY_API_URL = "https://devapi.ez-byte.com:5004/copy";

export default async function CopyService(
  copyData,
  accessToken,
  selectedFiles
) {
  // console.log(`COPY DATA IN SERVICE`);
  // console.log(copyData);

  console.log(selectedFiles);

  // Clean up currentName and newName by removing leading slashes
  const cleanedCopyData = copyData.copyItemList.map((item) => {
    let cleanCurrentName = item.currentName;

    // Remove leading slash if it exists for currentName
    if (cleanCurrentName.startsWith("/")) {
      cleanCurrentName = cleanCurrentName.slice(1);
    }

    // Check if it's a folder (no file extension)
    const hasExtension =
      cleanCurrentName.includes(".") && !cleanCurrentName.endsWith("/");

    // If there's no extension, add a trailing slash to mark it as a folder
    if (!hasExtension && !cleanCurrentName.endsWith("/")) {
      cleanCurrentName = `${cleanCurrentName}/`;
    } else if (hasExtension && cleanCurrentName.endsWith("/")) {
      // Remove trailing slash if it's a file
      cleanCurrentName = cleanCurrentName.slice(0, -1);
    }

    // Clean the newName (remove leading slash if any)
    let cleanNewName = item.newName;
    if (cleanNewName.startsWith("/")) {
      cleanNewName = cleanNewName.slice(1); // Remove leading slash for newName
    }

    // Check if newName represents a folder (no extension) and remove the last part if it's a folder
    const newNameHasExtension =
      cleanNewName.includes(".") && !cleanNewName.endsWith("/");

    if (!newNameHasExtension) {
      const parts = cleanNewName.split("/").filter(Boolean);

      // If only one part is left, set it to root "/"; otherwise, remove last part
      cleanNewName =
        parts.length > 1 ? `${parts.slice(0, -1).join("/")}/` : "/";
    }

    // Check if selectedFiles has a folder with isFolder set to true
    const folderFile = selectedFiles.find((file) => file.isFolder === true);
    if (folderFile) {
      // Get the last part of the folder path from selectedFiles
      const lastPart = folderFile.path.split("/").filter(Boolean).pop();

      // Separate the path and filename in cleanNewName
      const cleanNewNameParts = cleanNewName.split("/");
      const fileName = cleanNewNameParts.pop(); // Get the file name part
      const pathWithoutFileName = cleanNewNameParts.join("/"); // Get the path without the file name

      // Append lastPart into the path and then the file name
      cleanNewName = `${pathWithoutFileName}/${lastPart}/${fileName}`;
    }

    // Final check: Remove any leading slash in cleanNewName if it exists

    if (cleanNewName.startsWith("/") && cleanNewName !== "/") {
      cleanNewName = cleanNewName.slice(1);
    }

    return {
      ...item,
      currentName: cleanCurrentName,
      newName: cleanNewName,
    };
  });

  console.log("Cleaned Copy Data:", cleanedCopyData);

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${accessToken}`);

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ copyItemList: cleanedCopyData }),
  };

  try {
    const response = await fetch(`${COPY_API_URL}`, requestOptions);

    if (response.ok) {
      const result = await response.text();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during copy operation:", error);
    return false;
  }
}

// export default async function CopyService(copyData, accessToken) {
//   console.log(`COPY DATA IN SERVICE`);
//   console.log(copyData);
//   // Clean up currentName and newName by removing leading slashes
//   const cleanedCopyData = copyData.copyItemList.map((item) => {
//     let cleanCurrentName = item.currentName;

//     // Remove leading slash if it exists for currentName
//     if (cleanCurrentName.startsWith("/")) {
//       cleanCurrentName = cleanCurrentName.slice(1);
//     }

//     // Check if it's a folder (no file extension)
//     const hasExtension =
//       cleanCurrentName.includes(".") && !cleanCurrentName.endsWith("/");

//     // If there's no extension, add a trailing slash to mark it as a folder
//     if (!hasExtension && !cleanCurrentName.endsWith("/")) {
//       cleanCurrentName = `${cleanCurrentName}/`;
//     } else if (hasExtension && cleanCurrentName.endsWith("/")) {
//       // Remove trailing slash if it's a file
//       cleanCurrentName = cleanCurrentName.slice(0, -1);
//     }

//     // Clean the newName (remove leading slash if any)
//     let cleanNewName = item.newName;
//     if (cleanNewName.startsWith("/")) {
//       cleanNewName = cleanNewName.slice(1); // Remove leading slash for newName
//     }

//     return {
//       ...item,
//       currentName: cleanCurrentName, // Updated currentName with correct formatting
//       newName: cleanNewName, // Cleaned up newName (no leading slash)
//     };
//   });

//   console.log("Cleaned Copy Data:", cleanedCopyData); // Log the cleaned data for debugging

//   const headers = new Headers();
//   headers.append("Content-Type", "application/json");
//   headers.append("Authorization", `Bearer ${accessToken}`);

//   const requestOptions = {
//     method: "POST",
//     headers: headers,
//     body: JSON.stringify({ copyItemList: cleanedCopyData }), // Send the cleaned data
//   };

//   try {
//     const response = await fetch(`${COPY_API_URL}`, requestOptions);

//     if (response.ok) {
//       const result = await response.text();
//       return true;
//     } else {
//       return false;
//     }
//   } catch (error) {
//     console.error("Error during copy operation:", error);
//     return false;
//   }
// }
