import "../newComponent/Header.css";

import logo1 from "../assets/logo1.jpg";
import profile from "../assets/user.png";
import Bell from "../assets/bell.png";
import Settings from "../assets/settings.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

import appLogo from "../assets/EZ-BYTE-black.png";
import SearchInput from "../components/SearchInput";

function Header1({ email, onLogout, name, photoURL, files, handleFileSearch}) {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function handelLogoClick() {
    navigate("/home");
  }

  function toggleDrawer() {
    setIsDrawerOpen((prev) => !prev);
  }

  return (
    <>
      <header className="header1">
        <div className="header1-logo">
          <img
            src={logo1}
            alt="Company Logo"
            onClick={handelLogoClick}
            style={{ borderRadius: "5px" }}
          />
          <span>Drive</span>
        </div>

        <div>
          <SearchInput files={files} handleFileSearch={handleFileSearch} />
        </div>

        <div className="header1-icons">
          <span>
            <img className="left-icon" src={Bell} alt="NotificationIcon" />
            <img className="left-icon" src={Settings} alt="SetingsIcon" />
          </span>
          <span>
            <img src={profile} alt="Profile Icon" onClick={toggleDrawer} />
          </span>
        </div>
      </header>

      {/* Custom Drawer  */}
      <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
        <div className="drawer-content1">
          <img
            src={photoURL || appLogo}
            alt="UserImage"
            className="profile-photo"
          />

          <div className="name-div1">
            <p>Hi! {name}</p>
            <span>{email}</span>
          </div>
        </div>

        <div className="drawer-options1">
          <div className="drawer-option1">
            <PermIdentityOutlinedIcon />
            <span>Profile Settings</span>
          </div>

          <div className="drawer-option1">
            <DarkModeOutlinedIcon />
            <span>Apperence</span>
          </div>

          <div className="drawer-option1">
            <PrivacyTipOutlinedIcon />
            <span>Privacy & Security</span>
          </div>
          <div className="drawer-option1">
            <ManageAccountsOutlinedIcon />
            <span>Manage Accounts</span>
          </div>

          <div className="drawer-option1">
            <HelpOutlineOutlinedIcon />
            <span>Help</span>
          </div>

          <div className="drawer-option1">
            <PolicyOutlinedIcon />
            <span>Privacy Policy</span>
          </div>

          <div className="logout-btn1" onClick={onLogout}>
            <LogoutOutlinedIcon />
            <button>Logout</button>
          </div>
        </div>
      </div>
      {/* Overlay when drawer is open */}
      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={toggleDrawer}></div>
      )}
    </>
  );
}

export default Header1;
