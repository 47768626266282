import pdfIcon from "../assets/pdf.png";
import pngIcon from "../assets/png.png";
import htmlIcon from "../assets/html.png";
import jpegIcon from "../assets/jpeg.png";
import jpgIcon from "../assets/jpg.png";
import jsonIcon from "../assets/json.png";
import mpgIcon from "../assets/mpg-file-format.png";
import mp3Icon from "../assets/music.png";
import txtIcon from "../assets/txt.png";
import videoIcon from "../assets/video.png";
import xmlIcon from "../assets/xml.png";
import zipIcon from "../assets/zip.png";
import docIcon from "../assets/word (1).png";
// import commonIcon from "../assets/image.png";
import webpIcon from "../assets/webp.png";
import fileIcon from "../assets/file.png";
// import commonIcon from "../assets/img.png";
import commonIcon from "../assets/camera.png";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const fileIcons = {
  pdf: pdfIcon,
  png: commonIcon,
  html: htmlIcon,
  jpeg: commonIcon,
  jpg: commonIcon,
  json: jsonIcon,
  mpg: mpgIcon,
  mp3: mp3Icon,
  txt: txtIcon,
  mp4: videoIcon,
  xml: xmlIcon,
  zip: zipIcon,
  doc: docIcon,
  webp: commonIcon,

  default: pdfIcon,
};

export default fileIcons;
