import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "../components/SearchInput.css";


export default function SearchInput({ files, handleFileSearch }) {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchInputKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            fileSearch();
        }
    };
    const handleSearchBlur = () => {
        setSearchTerm("");
    };
    const fileSearch = () => {
        setSearchTerm((prev) => prev.trim());
        if (searchTerm.trim().length > 0) {
            const filteredItems = files.filter((file) =>
                file.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            const newCurrentItems = filteredItems.map((item) => ({
                ...item,
                path: item.name,
                name: item.name.split("/").pop(),
                location: item.name.split("/").slice(0, -1).pop(),
                isFolder: item.folder,
            }));
            handleFileSearch(newCurrentItems);
        }
    };
    return (
        <div className="header1-search">
            <SearchIcon />
            <input type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleSearchInputKeyDown}
                onBlur={handleSearchBlur}
            />
        </div>
    )
}