import React from "react";
import { useState, useEffect } from "react";
import {
  AiOutlineClose,
  AiFillCaretLeft,
  AiFillCaretRight,
} from "react-icons/ai";
import "./FilePreviewOverlay.css";
import FilePreview from "./FilePreview";

export default function FilePreviewOverlay({
  fileName,
  fileIndex,
  allFiles,
  onClose,
  onFullScreen,
  accessToken,
}) {
  const [currentFile, setCurrentFile] = useState(null);
  const [currentFilePath, setCurrentFilePath] = useState();
  const [currentFileIndex, setCurrentFileIndex] = useState(fileIndex);
  const [fileUpdated, setFileUdpated] = useState(false);
  const [disablePrev, setDisablePrev] = useState(
    fileIndex === 0 ? true : false
  );
  const [disableNext, setDisableNext] = useState(
    fileIndex === allFiles.length - 1 ? true : false
  );

  const handleArrow = (i) => {
    setFileUdpated(false);
    setCurrentFileIndex((indx) => indx + i);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!disablePrev) {
        if (event.key === "ArrowLeft") {
          handleArrow(-1);
        }
      }
      if (!disableNext) {
        if (event.key === "ArrowRight") {
          handleArrow(1);
        }
      }
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [disablePrev, disableNext]);

  useEffect(() => {
    currentFileIndex === 0 ? setDisablePrev(true) : setDisablePrev(false);
    currentFileIndex === allFiles.length - 1
      ? setDisableNext(true)
      : setDisableNext(false);
    const name = allFiles[currentFileIndex];

    setCurrentFile(name.name);
    setCurrentFilePath(name.path ? name.path : name.name);
    setFileUdpated(true);
  }, [currentFileIndex]);

  return (
    <div className={`overlay ${fileName ? "active" : ""}`}>
      <div className="overlay-content">
        {/* Header */}
        <div className="HeaderContainer">
          <div className="HeaderTitle">{currentFile}</div>
          <div className="HeaderClose">
            <AiOutlineClose className="close-btn" onClick={onClose} />
          </div>
        </div>
        {/* Content */}
        <div className="preview">
          <div className="leftArrow-icon-container">
            {!disablePrev && (
              <AiFillCaretLeft
                className="leftArrow"
                onClick={() => handleArrow(-1)}
              />
            )}
          </div>
          <div className="previewContent custom-scrollbar">
            {fileUpdated && (
              <FilePreview
                FilePath={currentFilePath}
                accessToken={accessToken}
              />
            )}
          </div>
          <div className="rightArrow-icon-container">
            {!disableNext && (
              <AiFillCaretRight
                className="rightArrow"
                onClick={() => handleArrow(1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
