import Axios from "axios";

export const CREATE_FOLDER_API_URL =
  process.env.REACT_APP_CREATE_FOLDER_API_URL;

// export const CREATE_FOLDER_API_URL =
//   "https://devapi.ez-byte.com:5001/createFolder";

export default async function CreateFolderService(folderData, accessToken) {
  console.log(folderData);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const createFolderPayLoad = {
    newFolderNames: folderData.map((folder) => folder.newFolderName),
  };

  console.log("Headers In Create Folder:", headers);
  console.log(`------------------------- PAYLOAD  -------------------------`);
  console.log(createFolderPayLoad);
  console.log(`--------------------------------------------------`);

  try {
    const response = await Axios.post(
      CREATE_FOLDER_API_URL,
      createFolderPayLoad,
      { headers }
    );

    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(`AXIOS ERROR:`, error);
    return false;
  }
}
