import { useEffect, useState } from "react";
import { auth, db } from "../config/firebase";
import { Navigate, useNavigate } from "react-router-dom";

import "../screens/UploadScreen.css";

import { doc, getDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { RingLoader } from "react-spinners";
import { FaRemoveFormat } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

export const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL;

export default function UploadScreen({ closeOverlay }) {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingName, setEditingName] = useState("");

  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [filesLoading, setFileLoading] = useState(false);

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!userDetail) {
    return <div>No user details available</div>;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);

      Navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    addFiles(newFiles);
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    addFiles(newFiles);
  };

  const addFiles = (newFiles) => {
    const updatedFiles = [
      ...files,
      ...newFiles.map((file) => ({
        file,
        name: file.name,
        size: file.size,
        category: "",
      })),
    ];
    setFiles(updatedFiles);
    console.log(updatedFiles);
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleEditFileName = (index, newName) => {
    const updatedFiles = [...files];
    const fileExtension = getFileExtension(updatedFiles[index].name);
    updatedFiles[index].name = `${newName}.${fileExtension}`;
    setFiles(updatedFiles);
    setEditingIndex(null); // Exit edit mode
  };

  const handleChangeCategory = (index, newCategory) => {
    const updatedFiles = [...files];
    updatedFiles[index].category = newCategory;
    setFiles(updatedFiles);
  };

  const preventDefault = (e) => e.preventDefault();

  const getFileNameWithoutExtension = (fileName) => {
    return fileName.split(".").slice(0, -1).join(".");
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  const handleSubmit = async () => {
    setFileLoading(true);

    const formData = new FormData();

    files.forEach((fileWrapper) => {
      console.log(fileWrapper); // Log to check fileWrapper contents

      // Make sure all values are defined and not null
      formData.append("files", fileWrapper.file);
      formData.append("category", fileWrapper.category || "3300"); // Default value if category is missing
      formData.append("name", fileWrapper.name || "defaultName"); // Add a default value for name if empty
      formData.append("userid", userDetail.email || "defaultUser"); // Add a default value for userid if empty
      formData.append("path", fileWrapper.path || "/"); // Ensure path is added
    });

    // Debugging: Log FormData to see what is being sent
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    try {
      const response = await fetch(
        // "https://159.65.181.101:5001/multi/details",
        `${UPLOAD_API_URL}/multi/details`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Upload successful:", data);
        closeOverlay();
      } else {
        console.error("Upload failed:", data);
        setError("Upload failed: " + data.message);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files.");
    } finally {
      setFileLoading(false);
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size < 1048576) return (size / 1024).toFixed(2) + " KB";
    else return (size / 1048576).toFixed(2) + " MB";
  };

  return (
    <>
      <>
        <div className="upload-page-container">
          <div className="content-wrapper">
            <div className="upload-container">
              <div
                className="drop-zone"
                onDrop={handleDrop}
                onDragOver={preventDefault}
                onDragEnter={preventDefault}
              >
                Drag and drop file(s) here
              </div>
              <button
                className="select-button"
                onClick={() => document.getElementById("file-input").click()}
              >
                Select Files
              </button>
              {filesLoading && (
                <div className="spinner-container">
                  <RingLoader color="#3498db" />
                  <p>Loading..please wait</p>
                </div>
              )}
              <input
                id="file-input"
                type="file"
                multiple
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
              {files.length > 0 ? (
                <div className="upload-file-table-container">
                  <table className="upload-file-table">
                    <thead>
                      <tr className="table-heading">
                        <th className="remove">
                          <FaX className="icon-close" />
                        </th>
                        <th className="file-name">File Name</th>
                        <th className="file-size">File Size</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((fileWrapper, index) => (
                        <tr key={index}>
                          <td className="remove">
                            {/* <button
                              className="delete-button"
                              onClick={() => handleDelete(index)}
                            >
                              X
                            </button> */}
                            <FaX
                              className="icon-close"
                              onClick={() => handleDelete(index)}
                            />
                          </td>
                          <td>
                            {editingIndex === index ? (
                              <input
                                type="text"
                                value={editingName}
                                onChange={(e) => setEditingName(e.target.value)}
                                onBlur={() =>
                                  handleEditFileName(index, editingName)
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleEditFileName(index, editingName);
                                  }
                                }}
                                autoFocus
                              />
                            ) : (
                              <span
                                className="data-name"
                                onClick={() => {
                                  setEditingIndex(index);
                                  setEditingName(
                                    getFileNameWithoutExtension(
                                      fileWrapper.name
                                    )
                                  );
                                }}
                              >
                                {fileWrapper.name}
                              </span>
                            )}
                          </td>
                          {/* <td>{formatFileSize(fileWrapper.size)}</td> */}
                          <td>
                            <span className="data-name">
                              {formatFileSize(fileWrapper.size)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="no-files-message">No files selected.</div>
              )}
              <div className="submit-button-container">
                {files.length > 0 && (
                  <button
                    className="submit-button"
                    disabled={filesLoading}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>

      {/* <div className="upload-page-container">
        <div className="content-wrapper">
          <div className="upload-container">
            <div
              className="drop-zone"
              onDrop={handleDrop}
              onDragOver={preventDefault}
              onDragEnter={preventDefault}
            >
              Drag and drop file(s) here
            </div>
            <button
              className="select-button"
              onClick={() => document.getElementById("file-input").click()}
            >
              Select Files
            </button>
            {filesLoading && (
              <div className="spinner-container">
                <RingLoader color="#3498db" />
                <p>Loading..please wait</p>
              </div>
            )}
            <input
              id="file-input"
              type="file"
              multiple
              onChange={handleFileSelect}
              style={{ display: "none" }}
            />
            {files.length > 0 ? (
              <div className="upload-file-table-container">
                <table className="upload-file-table">
                  <thead>
                    <tr>
                      <th className="remove">Remove</th>
                      <th className="file-name">File Name</th>
                      <th className="file-size">File Size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((fileWrapper, index) => (
                      <tr key={index}>
                        <td className="remove">
                          <button
                            className="delete-button"
                            onClick={() => handleDelete(index)}
                          >
                            X
                          </button>
                        </td>
                        <td>
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={editingName}
                              onChange={(e) => setEditingName(e.target.value)}
                              onBlur={() =>
                                handleEditFileName(index, editingName)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleEditFileName(index, editingName);
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <span
                              className="data-name"
                              onClick={() => {
                                setEditingIndex(index);
                                setEditingName(
                                  getFileNameWithoutExtension(fileWrapper.name)
                                );
                              }}
                            >
                              {fileWrapper.name}
                            </span>
                          )}
                        </td>
                        <td>{formatFileSize(fileWrapper.size)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-files-message"></div>
            )}
            {files.length > 0 && (
              <button
                className="submit-button"
                disabled={filesLoading}
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
}
