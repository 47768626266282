import axios from "axios";

export const DROP_URL = "https://devapi.ez-byte.com:5001/multi/details";

export const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL;

export async function FilesandFolderDropService(
  filesData,
  accessToken,
  userDetail
) {
  const uploadRequest = filesData.map((fileWrapper) => {
    const { file, name, size, path, category } = fileWrapper;
    return {
      file,
      name: name || "defaultName",
      size,
      path: path || "", // Keep empty string for root
      category: category || "3300",
      userid: userDetail.email || "defaultUser",
    };
  });

  const responses = [];
  let breakdownFiles = [];

  for (let i = 0; i < uploadRequest.length; i++) {
    breakdownFiles.push(uploadRequest[i]);

    if ((i + 1) % 4 === 0 || i === uploadRequest.length - 1) {
      console.log("=============================");
      console.log("Submitting group for upload:", breakdownFiles);

      const formData = new FormData();
      breakdownFiles.forEach((fileWrapper) => {
        formData.append("files", fileWrapper.file);
        formData.append("category", fileWrapper.category);
        formData.append("name", fileWrapper.name);
        formData.append("userid", fileWrapper.userid);

        // Conditional path handling
        if (fileWrapper.path === "") {
          formData.append("path", "");
          formData.append("path", ""); // Duplicate for empty path case
        } else {
          formData.append("path", fileWrapper.path);
        }
      });

      try {
        const headers = {
          "Cache-Control": "no-cache",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.post(
          `${UPLOAD_API_URL}/multi/details`,
          formData,
          { headers }
        );

        console.log("Upload response data:", response.data);

        responses.push(response.data);
      } catch (error) {
        console.error("Error uploading files:", error);
        throw error;
      }

      breakdownFiles = [];

      console.log("=============================");
    }
  }

  return responses;
}

// export async function FilesandFolderDropService(
//   filesData,
//   accessToken,
//   userDetail
// ) {
//   console.log("Starting file upload with data:", filesData, userDetail.email);

//   // Copy `filesData` into a new array uploadRequest
//   const uploadRequest = filesData.map((fileWrapper) => {
//     const { file, name, size, path, category } = fileWrapper;
//     return {
//       file,
//       name: name || "defaultName",
//       size,
//       path: path || "",
//       category: category || "3300",
//       userid: userDetail.email || "defaultUser",
//     };
//   });

//   let breadownList = [];
//   const responses = []; // Array to store responses

//   // Iterate over `uploadRequest` and process files in groups of 4
//   for (let i = 0; i < uploadRequest.length; i++) {
//     breadownList.push(uploadRequest[i]);

//     // If 4 items are in breadownList or it’s the last batch, submit the POST request
//     if ((i !== 0 && (i + 1) % 4 === 0) || i === uploadRequest.length - 1) {
//       console.log("=============================");
//       console.log("Submitting group for upload:", breadownList);

//       // Create a new FormData for each batch
//       const formData = new FormData();
//       breadownList.forEach((fileWrapper) => {
//         formData.append("files", fileWrapper.file);
//         formData.append("category", fileWrapper.category);
//         formData.append("name", fileWrapper.name);
//         formData.append("path", fileWrapper.path);
//         // formData.append("path", fileWrapper.path);
//         formData.append("userid", fileWrapper.userid);
//       });

//       try {
//         const headers = {
//           "Cache-Control": "no-cache",
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${accessToken}`,
//         };

//         // Submit the actual POST request
//         const response = await axios.post(DROP_URL, formData, { headers });
//         console.log("Upload response data:", response.data);

//         // Store each response
//         responses.push(response.data);
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         throw error;
//       }

//       // Reset `breadownList` for the next group
//       breadownList = [];
//       console.log("=============================");
//     }
//   }

//   console.log("All file batches processed successfully.");
//   return responses; // Return all responses after processing all batches
// }

// export async function FilesandFolderDropService(
//   filesData,
//   accessToken,
//   userDetail
// ) {
//   console.log("Starting file upload with data:", filesData, userDetail.email);

//   // Copy `filesData` into a new array `uploadRequest`
//   const uploadRequest = filesData.map((fileWrapper) => {
//     const { file, name, size, path, category } = fileWrapper;
//     return {
//       file,
//       name: name || "defaultName",
//       size,
//       path: path || "",
//       category: category || "3300",
//       userid: userDetail.email || "defaultUser",
//     };
//   });

//   let breadownList = [];

//   // Iterate over `uploadRequest` and process files in groups of 4
//   for (let i = 0; i < uploadRequest.length; i++) {
//     breadownList.push(uploadRequest[i]);

//     // If 4 items are in breadownList or it’s the last batch, submit the POST request
//     if ((i !== 0 && (i + 1) % 4 === 0) || i === uploadRequest.length - 1) {
//       console.log("=============================");
//       console.log("Submitting group for upload:", breadownList);

//       // Create a new FormData for each batch
//       const formData = new FormData();
//       breadownList.forEach((fileWrapper) => {
//         formData.append("files", fileWrapper.file);
//         formData.append("category", fileWrapper.category);
//         formData.append("name", fileWrapper.name);
//         formData.append("path", fileWrapper.path);
//         formData.append("userid", fileWrapper.userid);
//       });

//       try {
//         const headers = {
//           "Cache-Control": "no-cache",
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${accessToken}`,
//         };

//         // console.log(formData);
//         const response = await axios.post(DROP_URL, formData, { headers });
//         console.log("Upload response data:", response.data);
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         throw error;
//       }

//       // Reset `breadownList` for the next group
//       breadownList = [];
//       console.log("=============================");
//     }
//   }

//   console.log("All file batches processed successfully.");
// }

// export async function FilesandFolderDropService(
//   filesData,
//   accessToken,
//   userDetail
// ) {
//   console.log("Starting file upload with data:", filesData, userDetail.email);

//   const formData = new FormData();

//   // Loop through each file/folder and append data to the FormData
//   filesData.forEach((fileWrapper) => {
//     const { file, name, size, path } = fileWrapper;

//     formData.append("files", file);
//     formData.append("category", fileWrapper.category || "3300");
//     formData.append("name", name || "defaultName");
//     formData.append("path", path || "");
//     formData.append("userid", userDetail.email || "defaultUser");
//     console.log(`Uploading file: ${name}, Size: ${size}, Path: ${path}`);
//   });

//   try {
//     const headers = {
//       "Cache-Control": "no-cache",
//       "Content-Type": "multipart/form-data", // Ensure correct Content-Type for file upload
//       Authorization: `Bearer ${accessToken}`,
//     };

//     // Send the FormData to the backend
//     console.log(`------------------------------------------------`);
//     console.log(`FORM DATA`);
//     console.log(formData);
//     // Debugging: Log FormData to see what is being sent
//     for (let pair of formData.entries()) {
//       console.log(pair[0] + ": " + pair[1]);
//     }
//     console.log(`----------------------------------------------------`);

//     const response = await axios.post(DROP_URL, formData, { headers });

//     console.log("Upload response data:", response.data);

//     return response.data;
//   } catch (error) {
//     console.error("Error uploading files:", error);
//     throw error;
//   }
// }
