import { auth, db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";

import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../screens/HomeScreen.css";

import { RingLoader } from "react-spinners";
import { message, Tooltip } from "antd";

import UploadOverlay from "../components/UploadOverlay";
import EmailOverlay from "../components/EmailOverlay";
import FilePreviewOverlay from "../components/FilePreview/FilePreviewOverlay";
import RenameDialog from "../newComponent/RenameDialog";

import DeleteService from "../services/deleteService";
import RenameService from "../services/renameService";

import "../components/FilePreview/FilePreviewOverlay";

import Header1 from "../newComponent/Header";
import SideBar1 from "../newComponent/SideBar";
import Axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { jwtDecode } from "jwt-decode";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  EmailOutlined as EmailOutlinedIcon,
  DriveFileRenameOutlineOutlined as DriveFileRenameOutlineOutlinedIcon,
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  TrendingFlatOutlined as TrendingFlatOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  DriveFileMoveOutlined as DriveFileMoveOutlinedIcon,
  ContentPasteOutlined as ContentPasteOutlinedIcon,
  FolderOutlined,
  ContentCutOutlined as ContentCutOutlinedIcon,
} from "@mui/icons-material";

import { useUserDetails } from "../customHook/userDetails";
import SortableHeader from "../components/SortableHeader";
import CreateFolderModal from "../newComponent/FolderModal";
import CreateFolderService from "../services/createFolderService";
import DownloadService from "../services/downloadService";
import fileIcons from "../newComponent/FileIcons";
import CopyService from "../services/copyService";
import CutService from "../services/cutService";
import { FilesandFolderDropService } from "../services/FilesandFolderDropService";

export const READ_API_URL = process.env.REACT_APP_READ_API_URL;

const createFolderTree = (data) => {
  const tree = {};
  const rootFolders = new Set();

  data.forEach((item) => {
    const parts = item.name.split("/");

    // Add the first part of the path (root folder) to rootFolders
    if (parts.length > 1) {
      rootFolders.add(parts[0]);

      // console.log(`K${parts[0]}K`);
    }

    // Traverse through the folder tree structure
    let currentLevel = tree;
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      // console.log(`S${part}S`);

      // Initialize an object if the folder does not exist
      if (!currentLevel[part]) {
        currentLevel[part] = {}; // Only create the folder structure
      }

      // Move to the next level
      currentLevel = currentLevel[part];
    }
  });

  return { tree, rootFolders: Array.from(rootFolders) };
};

const optionsIcons = {
  Preview: <VisibilityOutlinedIcon />,
  Download: <FileDownloadOutlinedIcon />,
  Email: <EmailOutlinedIcon />,
  Rename: <DriveFileRenameOutlineOutlinedIcon />,
  Copy: <ContentCopyOutlinedIcon />,
  Cut: <ContentCutOutlinedIcon />,
  Delete: <DeleteOutlinedIcon />,
  Paste: <ContentPasteOutlinedIcon />,
  Folder: <FolderOutlined />,
};

export default function HomeScreen() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState("Home"); // Track selected folder
  const [files, setFiles] = useState([]); // Files from the backend

  const [currentFolderPath, setCurrentFolderPath] = useState(""); // State to store current folder path

  const [folderTree, setFolderTree] = useState({});
  const [currentItems, setCurrentItems] = useState([]); // Items (files/folders) being displayed
  const [rootFolders, setRootFolders] = useState([]); // Root folders to display
  const [initialItems, setInitialItems] = useState([]); // New state for initial items

  const [checkedFiles, setCheckedFiles] = useState([]); // State to track checked files
  const [isAllSelected, setIsAllSelected] = useState(false); // State for the "Select All" checkbox

  const [messageApi, messageContextHolder] = message.useMessage();

  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);

  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
  const [selectedFilesToEmail, setSelectedFilesToEmail] = useState([]);

  const [isFilePreviewOverlayOpen, setIsFilePreviewOverlayOpen] =
    useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState();
  const [currentFileIndex, setCurrentFileIndex] = useState();

  const [multipleFileSelect, setMultipleFileSelect] = useState([]);

  const [expandedFolders, setExpandedFolders] = useState([]); // Keep track of expanded folders

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false); // State for Rename Dialog
  const [fileToRename, setFileToRename] = useState(null); // Track file to rename

  const [isAscending, setIsAscending] = useState(true); // State for sorting order

  const [isAscendingDate, setIsAscendingDate] = useState(true); // State for date sorting order
  const [isAscendingSize, setIsAscendingSize] = useState(true); // For sorting by size

  const [isAscendingReceivedDate, setIsAscendingReceivedDate] = useState(true);

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);

  const [filteredItem, setFilteredItem] = useState("");

  const [dragActive, setDragActive] = useState(false);
  const [folderFiles, setFolderFiles] = useState([]);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    options: [],
  });

  const { userDetail, refreshAccessToken } = useUserDetails();

  const [accessToken, setAccessToken] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [contextMenuIndex, setContextMenuIndex] = useState(-1);

  const [copiedPaths, setCopiedPaths] = useState([]);
  const [cutPaths, setCutPaths] = useState([]);
  const [operationType, setOperationType] = useState(""); // Can be "cut" or "copy"
  const [isSearchResults, setIsSearchResults] = useState(false);
  const selectedIndexRef = useRef(selectedIndex); // Ref to store selectedIndex

  // Helper function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      console.log("Token expiration time:", decodedToken.exp);
      console.log("Current time:", currentTime);
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token: ", error);
      return true;
    }
  };

  console.log(accessToken);

  // Check for accessToken on component mount
  useEffect(() => {
    const initializeAccessToken = async () => {
      const tokenFromState = location.state ? location.state.accessToken : null;
      const tokenFromStorage = localStorage.getItem("accessToken");

      let tokenToUse = tokenFromState || tokenFromStorage;

      if (tokenToUse) {
        // Check if token is expired
        if (isTokenExpired(tokenToUse)) {
          console.log("Token is expired. Refreshing...");
          await refreshAccessToken(); // Wait for the token to be refreshed
          tokenToUse = localStorage.getItem("accessToken"); // Get the new token after refreshing
          console.log("REFERSHED");
        }
        setAccessToken(tokenToUse); // Set the updated token
        console.log("Not EXPIRED");
      } else {
        navigate("/login"); // No token, redirect to login
      }
    };

    initializeAccessToken(); // Call the async function

    // Set an interval to refresh the token periodically
    const intervalId = setInterval(async () => {
      if (accessToken && isTokenExpired(accessToken)) {
        console.log("Token is expired. Refreshing...");
        await refreshAccessToken(); // Refresh the token
        const newToken = localStorage.getItem("accessToken"); // Get the new token after refreshing

        // Log the expiration time of the new token
        const decodedNewToken = jwtDecode(newToken);
        console.log("New token expiration time:", decodedNewToken.exp);
        setAccessToken(newToken); // Update the access token in state
      }
    }, 3000000); // 50 minutes in milliseconds (59 * 60 * 1000)

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [location.state, navigate, refreshAccessToken, accessToken]);

  // FETCHING FILES AND FOLDERS

  const fetchFilesAndFolders = (selFolder) => {
    const headers = {
      "Cache-Control": "no-cache",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`,
    };

    Axios.get(`${READ_API_URL}/readAll`, {
      headers: headers,
    })
      .then((response) => {
        const data = response.data;

        // console.log("Fetched data:", data);

        const files = data.filter((item) => !item.name.includes("/")); // Only files not in folders

        console.log(`FILES`);
        console.log(files.map((item) => `|${item.name}|`));

        const folders = data.filter((item) => item.name.includes("/"));
        console.log(`FOLDERS`);
        console.log(folders.map((item) => `|${item.name}|`));

        const { tree, rootFolders } = createFolderTree(folders);

        setFolderTree(tree);
        setFiles(data);
        setRootFolders(rootFolders);

        // Set initial items to root-level files and folders
        const initialDisplayItems = [
          ...files.map((file) => ({
            name: file.name,
            isFolder: false,
            size: file.size,
            createdDate: file.createdDate,
            path: `/${file.name}`,
          })),
          ...rootFolders.map((folder) => ({
            name: folder,
            isFolder: true,
            path: `/${folder}`,
          })),
        ];

        setInitialItems(initialDisplayItems); // Save initial items

        setCurrentItems(initialDisplayItems); // Set current items to initial
        setLoading(false);
        // handleFolderClick(selectedFolder);
        console.log(`X${selectedFolder}X`);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (accessToken) {
      fetchFilesAndFolders();
    }
  }, [accessToken]);

  // Sync isAllSelected state with checked files
  useEffect(() => {
    const totalFiles = currentItems.filter((item) => !item.isFolder).length;
    setIsAllSelected(checkedFiles.length === totalFiles && totalFiles > 0);
  }, [checkedFiles, currentItems]);

  // ----------------------------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      const container2 = document.querySelector(".container2-body");
      const contextMenuElement = document.querySelector(".context-menu");

      if (
        !container2?.contains(event.target) &&
        !contextMenuElement?.contains(event.target)
      ) {
        handleContextMenuClose();
      }
    };

    document[contextMenu.visible ? "addEventListener" : "removeEventListener"](
      "mousedown",
      handleClickOutside
    );

    // Disable scroll when context menu is open
    if (contextMenu.visible) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [contextMenu.visible]);

  // ----------------------------------------------

  // const handleKeyDown = (event) => {
  //   if (isRenameDialogOpen) {
  //     return; // Prevent further actions if rename popup is active
  //   }

  //   if (contextMenu.visible) {
  //     event.preventDefault();
  //     // Handle context menu navigation
  //     if (event.key === "ArrowDown") {
  //       setContextMenuIndex((prevIndex) =>
  //         prevIndex < contextMenu.options.length - 1 ? prevIndex + 1 : prevIndex
  //       );
  //     } else if (event.key === "ArrowUp") {
  //       setContextMenuIndex((prevIndex) =>
  //         prevIndex > 0 ? prevIndex - 1 : prevIndex
  //       );
  //     } else if (event.key === "Enter" && contextMenuIndex >= 0) {
  //       handelContextMenuOptionClick(
  //         contextMenu.options[contextMenuIndex],
  //         multipleFileSelect
  //       );
  //     } else if (event.key === "Escape") {
  //       handleContextMenuClose();
  //     }
  //   } else {
  //     // Handle file navigation with Arrow keys only
  //     if (event.key === "ArrowDown" || event.key === "ArrowUp") {
  //       setSelectedIndex((prevIndex) => {
  //         const newIndex =
  //           event.key === "ArrowDown"
  //             ? Math.min(prevIndex + 1, currentItems.length - 1)
  //             : Math.max(prevIndex - 1, 0);
  //         selectedIndexRef.current = newIndex;
  //         return newIndex;
  //       });

  //       // Update `multipleFileSelect` only when using Arrow keys
  //       const selectedItem = currentItems[selectedIndexRef.current];
  //       if (selectedItem) {
  //         setMultipleFileSelect([
  //           {
  //             name: selectedItem.name,
  //             size: selectedItem.size,
  //             path: selectedItem.path,
  //             isFolder: selectedItem.isFolder,
  //           },
  //         ]);
  //       }
  //     }

  //     // Handle Enter key for file/folder interaction
  //     if (event.key === "Enter") {
  //       const selectedItem = currentItems[selectedIndexRef.current];
  //       if (selectedItem && selectedItem.isFolder) {
  //         handleFolderClick(selectedItem.path);
  //       } else if (selectedItem && !selectedItem.isFolder) {
  //         const index = currentItems.findIndex(
  //           (_item) => _item.name === selectedItem.name
  //         );
  //         handleFilePreviewClick(
  //           selectedItem.name,
  //           selectedItem.path,
  //           index,
  //           currentItems
  //         );
  //       }
  //     }
  //   }
  // };

  const handleKeyDown = (event) => {
    if (isRenameDialogOpen) {
      return; // Prevent further actions if rename popup is active
    }

    if (contextMenu.visible) {
      event.preventDefault();
      // Handle context menu navigation
      if (event.key === "ArrowDown") {
        setContextMenuIndex((prevIndex) =>
          prevIndex < contextMenu.options.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (event.key === "ArrowUp") {
        setContextMenuIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
      } else if (event.key === "Enter" && contextMenuIndex >= 0) {
        handelContextMenuOptionClick(
          contextMenu.options[contextMenuIndex],
          multipleFileSelect
        );
      } else if (event.key === "Escape") {
        handleContextMenuClose();
      }
    } else {
      // Handle file navigation with Arrow keys only
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        setSelectedIndex((prevIndex) => {
          const newIndex =
            event.key === "ArrowDown"
              ? Math.min(prevIndex + 1, currentItems.length - 1)
              : Math.max(prevIndex - 1, 0);
          selectedIndexRef.current = newIndex;
          return newIndex;
        });

        // Update `multipleFileSelect` only when using Arrow keys
        const selectedItem = currentItems[selectedIndexRef.current];
        if (selectedItem) {
          setMultipleFileSelect([
            {
              name: selectedItem.name,
              size: selectedItem.size,
              path: selectedItem.path,
              isFolder: selectedItem.isFolder,
            },
          ]);
        }
      }

      // Handle Enter key for file/folder interaction
      if (event.key === "Enter") {
        const selectedItem = currentItems[selectedIndexRef.current];
        if (selectedItem && selectedItem.isFolder) {
          handleFolderClick(selectedItem.path);
        } else if (selectedItem && !selectedItem.isFolder) {
          const index = currentItems.findIndex(
            (_item) => _item.name === selectedItem.name
          );
          handleFilePreviewClick(
            selectedItem.name,
            selectedItem.path,
            index,
            currentItems
          );
        }
      }
    }
  };

  useEffect(() => {
    const selectedItem = document.querySelector(".details-Row.selected-file");
    if (selectedItem) {
      const container = document.querySelector(".container2-body");
      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;
      const itemTop = selectedItem.offsetTop;
      const itemBottom = itemTop + selectedItem.clientHeight;

      console.log({ containerTop, containerBottom, itemTop, itemBottom });

      // Scroll up: if the selected item is above the visible area of the container
      if (itemTop < containerTop) {
        // Add a slight offset to scroll the item a little higher even when at the top
        container.scrollTop = itemTop - 20; // Adjust "20" as needed for the slight offset
      }
      // Scroll down: if the selected item is below the visible area of the container
      else if (itemBottom > containerBottom) {
        container.scrollTop = itemBottom - container.clientHeight; // Scroll to the bottom of the item
      }
      // Ensure the item is always within the visible area when already partially visible
      else if (itemTop >= containerTop && itemBottom <= containerBottom) {
        // Optionally center the selected item within the visible area
        const offset = (container.clientHeight - selectedItem.clientHeight) / 1;
        container.scrollTop = itemTop - offset;
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    contextMenu.visible,
    contextMenu.options,
    contextMenuIndex,
    currentItems.length,
  ]);

  useEffect(() => {
    // Reset the selectedIndex to start from the top when currentItems changes
    setSelectedIndex(-1); // or 0 if you'd prefer the navigation to start at the first item
    setMultipleFileSelect([]);
  }, [currentItems]);

  // Update selected file based on `selectedIndex` change
  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < currentItems.length) {
      const selectedFile = currentItems[selectedIndex];
      setMultipleFileSelect([selectedFile]);
    }
  }, [selectedIndex]);

  console.log(multipleFileSelect);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [contextMenu, contextMenu.options, contextMenuIndex, currentItems.length]);

  // ------------------------------------

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" className="ring" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("accessToken"); // Remove token from localStorage
      setAccessToken(null);
      navigate("/login");
      console.log(`ACCESS TOKEN WHILE LOGOUT: ${accessToken}`);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Folder click handling

  const extractFileName = (filePath) => {
    return filePath.split("/").pop();
  };

  // // Folder click

  const handleFolderClick = async (folderPath) => {
    setIsSearchResults(false);
    console.log(`PATH BEFORE NORMALISING:  ${folderPath}`);

    // Normalize folderPath to remove leading slash
    let normalizedFolderPath = folderPath.startsWith("/")
      ? folderPath.substring(1)
      : folderPath;

    console.log(`PATH AFTER NORMALISING:   ${normalizedFolderPath}`);

    // Check if folderPath is 'Home'
    if (normalizedFolderPath === "Home") {
      fetchFilesAndFolders();
      setSelectedFolder("Home");
      setCurrentFolderPath("");
      setCurrentItems(initialItems); // Reset to root-level files and folders
      return;
    }

    // Set current folder path and selected folder
    setSelectedFolder(normalizedFolderPath);
    const newFolderPath = normalizedFolderPath
      ? `${normalizedFolderPath}/`
      : "";
    setCurrentFolderPath(newFolderPath);

    console.log(`A${newFolderPath}A`);

    // Filter files that are inside the selected folder
    const folderFiles = files.filter((file) => {
      const relativePath = file.name.substring(normalizedFolderPath.length + 1);
      return (
        file.name.startsWith(`${normalizedFolderPath}/`) &&
        relativePath.split("/").length === 1 &&
        !file.name.endsWith(".emptyfile")
      );
    });

    // Traverse the folder tree to find subfolders
    const folderParts = normalizedFolderPath.split("/");
    let currentFolderTree = folderTree;

    folderParts.forEach((part) => {
      if (currentFolderTree[part]) {
        currentFolderTree = currentFolderTree[part];
      }
    });

    // Find subfolders in the current folder
    const subfolders = Object.keys(currentFolderTree);
    const folderFolders = subfolders.map((subfolder) => ({
      name: `${normalizedFolderPath}/${subfolder}`,
      isFolder: true,
      path: `${normalizedFolderPath}/${subfolder}`,
    }));

    // Update the current items with files and subfolders
    const newCurrentItems = [
      ...folderFiles.map((file) => ({
        name: file.name,
        isFolder: false,
        path: file.name,
        createdDate: file.createdDate,
        size: file.size,
      })),
      ...folderFolders,
    ];

    const finalCurrentNames = [];

    console.log(
      `D${newCurrentItems.map((item) => {
        let mName = item.name;

        var pp = mName[mName.length - 1];

        console.log(`Z${pp}Z`);

        if (pp !== "/") {
          finalCurrentNames.push(item);
        }

        console.log(`Z${pp === "/"}Z`);
      })}D`
    );
    // Update the displayed items
    // setCurrentItems(newCurrentItems);
    setCurrentItems(finalCurrentNames);
  };

  const hardcodedReceivedDate = "2024-10-01";

  const handleUploadButtonClick = () => {
    setIsUploadOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsUploadOverlayOpen(false);
  };

  const handleFilePreviewClose = () => {
    setIsFilePreviewOverlayOpen(false);
  };

  const handleFilePreviewClick = (fileName, filePath, index, fileList) => {
    setSelectedFile(fileName);
    setSelectedFilePath(filePath);
    setCurrentFileIndex(index);
    setCurrentItems(fileList);
    setIsFilePreviewOverlayOpen(true);
  };

  // Close the email overlay after success
  const closeEmailOverLay = (result) => {
    setIsEmailOverlayOpen(false);
    if (result === true) {
      messageApi.open({
        type: "success",
        content: "Email sent successfully!",
        duration: 3,
      });
      setCheckedFiles([]);
      setIsAllSelected(false);
    }
  };

  /* HANDEL SINGLE AND MULTIPLE FILE CLICK AND CONTEXT MENU */

  // const handelSingleFileClick = (event, file, index) => {
  //   console.log(file);
  //   // MultiSelect with ctrl/meta key
  //   if (event.ctrlKey || event.metaKey) {
  //     handelMultipleFileClick(file);
  //   } else {
  //     // select single file (deselect others)
  //     const selectedFile = {
  //       name: file.name,
  //       // name:
  //       //   file.isFolder && !file.name.endsWith("/")
  //       //     ? `${file.name}/`
  //       //     : file.name,
  //       size: file.size,
  //       path: file.path,
  //       isFolder: file.isFolder,
  //     };

  //     if (multipleFileSelect.some((item) => item.name === selectedFile.name)) {
  //       // Deselect if already selected
  //       const filteredFile = multipleFileSelect.filter(
  //         (item) => item.name !== selectedFile.name
  //       );

  //       setMultipleFileSelect(filteredFile);
  //     } else {
  //       setMultipleFileSelect([selectedFile]);
  //     }

  //     setSelectedIndex(index); // Set selected index to the clicked file's index
  //     selectedIndexRef.current = index; // Update ref

  //     // setSelectedIndex(-1); // Set selected index to the clicked file's index
  //   }
  //   //  Hide context menu if visible
  //   setContextMenu({ visible: false });
  // };

  const handelSingleFileClick = (event, file, index) => {
    if (event.ctrlKey || event.metaKey) {
      handelMultipleFileClick(file);
    } else {
      const selectedFile = {
        name: file.name,
        size: file.size,
        path: file.path,
        isFolder: file.isFolder,
      };

      if (multipleFileSelect.some((item) => item.name === selectedFile.name)) {
        // Deselect if already selected
        const filteredFile = multipleFileSelect.filter(
          (item) => item.name !== selectedFile.name
        );
        setMultipleFileSelect(filteredFile);
        setSelectedIndex(-1); // Reset selected index
        selectedIndexRef.current = -1; // Reset selected index ref
      } else {
        setMultipleFileSelect([selectedFile]);
        setSelectedIndex(index); // Update selected index
        selectedIndexRef.current = index; // Update ref
      }
    }

    setContextMenu({ visible: false });
  };

  const handelMultipleFileClick = (file) => {
    const selectedFile = {
      name: file.name,
      size: file.size,
      path: file.path,
      isFolder: file.isFolder,
    };
    setMultipleFileSelect((prev) => {
      if (prev.some((item) => item.name === selectedFile.name)) {
        // If Already selected remove it
        return prev.filter((item) => item.name !== selectedFile.name);
      } else {
        return [...prev, selectedFile];
      }
    });
  };

  // const handleContextMenuClose = () => {
  //   setContextMenu({ visible: false });
  // };

  // const handelContextMenuOptionClick = (option, selectedFiles) => {
  //   console.log(`FILES COMMING INSIDE CONTEXT MENU OPTION CLICK`);
  //   console.log(selectedFiles);
  //   console.log(`FILES COMMING INSIDE CONTEXT MENU OPTION CLICK`);

  //   handleContextMenuClose();

  //   switch (option) {
  //     case "Preview":
  //       if (selectedFiles.length === 1) {
  //         const fileToPreview = selectedFiles[0];
  //         const index = currentItems.findIndex(
  //           (item) => item.name === fileToPreview.name
  //         );
  //         handleFilePreviewClick(
  //           fileToPreview.name,
  //           fileToPreview.path,
  //           index,
  //           currentItems
  //         );
  //         setIsFilePreviewOverlayOpen(true);
  //       }

  //       setMultipleFileSelect([]);

  //       break;

  //     case "Download":
  //       if (selectedFiles.length >= 1) {
  //         const processedFiles = selectedFiles.map((file) => {
  //           if (file.isFolder && !file.path.endsWith("/")) {
  //             return { ...file, path: `${file.path}/` };
  //           }
  //           return {
  //             ...file,
  //             path: file.path.startsWith("/") ? file.path.slice(1) : file.path,
  //           };
  //         });

  //         DownloadService(processedFiles, accessToken)
  //           .then((downloadResult) => {
  //             if (downloadResult) {
  //               console.log("Download successful");

  //               // Create a blob URL from the response
  //               const blob = new Blob([downloadResult], {
  //                 type: "application/octet-stream", // Adjust MIME type if necessary
  //               });
  //               const downloadUrl = window.URL.createObjectURL(blob);

  //               // Create a temporary link element to trigger the download
  //               const link = document.createElement("a");
  //               link.href = downloadUrl;

  //               console.log(selectedFiles);

  //               if (
  //                 selectedFiles.length === 1 &&
  //                 selectedFiles[0].isFolder === false
  //               ) {
  //                 link.setAttribute("download", selectedFiles[0].name); // Use the first file's name
  //               } else {
  //                 link.setAttribute("download", "CITRIXBOX-Downloaded.zip"); // Name it generically if multiple files are downloaded
  //               }

  //               document.body.appendChild(link);
  //               link.click();

  //               // Clean up by removing the link and revoking the blob URL
  //               link.parentNode.removeChild(link);
  //               window.URL.revokeObjectURL(downloadUrl);
  //             } else {
  //               console.log("Download failed");
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(`Error during download:`, error);
  //           });
  //       }

  //       setMultipleFileSelect([]);
  //       break;

  //     case "Email":
  //       if (selectedFiles.length >= 1) {
  //         console.log(selectedFiles.map((item) => item.path));

  //         setSelectedFilesToEmail(selectedFiles);
  //         console.log(`selected files for email: ${selectedFiles}`);
  //         setIsEmailOverlayOpen(true);
  //         setMultipleFileSelect([]);
  //       }
  //       break;

  //     case "Rename":
  //       console.log(selectedFiles.map((item) => item.name));

  //       if (selectedFiles.length === 1) {
  //         setFileToRename(selectedFiles[0]);
  //         setIsRenameDialogOpen(true); // Open the rename dialog

  //         setMultipleFileSelect([]);
  //       }
  //       break;

  //     case "Copy":
  //       console.log(selectedFiles);
  //       const pathsToCopy = selectedFiles.map((file) => ({
  //         currentName: file.isFolder
  //           ? file.path.endsWith("/")
  //             ? file.path
  //             : `${file.path}/` // Ensure trailing slash for folders
  //           : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
  //       }));
  //       setCopiedPaths(pathsToCopy);
  //       console.log("Copied Paths:", pathsToCopy);
  //       setOperationType("copy");
  //       setMultipleFileSelect([]);
  //       break;

  //     case "Paste":
  //       // Check if operation type is cut
  //       if (operationType === "cut" && cutPaths.length > 0) {
  //         const targetFolderPath = currentFolderPath.endsWith("/")
  //           ? currentFolderPath
  //           : `${currentFolderPath}/`;

  //         const cutItemList = cutPaths.map((item) => {
  //           const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
  //           const fileName = cleanCurrentName.split("/").pop(); // Extract filename

  //           return {
  //             currentName: item.currentName,
  //             newName: `${targetFolderPath}${fileName}${
  //               item.currentName.endsWith("/") ? "/" : ""
  //             }`,
  //           };
  //         });

  //         console.log("Constructed cutItemList:", cutItemList);

  //         CutService({ cutItemList }, accessToken)
  //           .then((result) => {
  //             if (result) {
  //               console.log("Files moved successfully");
  //               toast.success("Files moved successfully!", {
  //                 position: "top-center",
  //                 autoClose: 3000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 closeButton: false,
  //                 style: { color: "green", backgroundColor: "#f4f4f4" },
  //               });
  //             } else {
  //               console.log("Move failed");
  //               toast.error("Move failed!", {
  //                 position: "top-right",
  //                 autoClose: 2000,
  //                 hideProgressBar: true,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 style: { color: "#fff", backgroundColor: "#dc3545" },
  //               });
  //             }
  //           })
  //           .catch((error) => console.error("Error during move:", error))
  //           .finally(() => {
  //             setCutPaths([]); // Clear cutPaths after paste
  //             setOperationType(""); // Reset operation type
  //           });
  //       }

  //       // Check if operation type is copy
  //       else if (operationType === "copy" && copiedPaths.length > 0) {
  //         const targetFolderPath = currentFolderPath.endsWith("/")
  //           ? currentFolderPath
  //           : `${currentFolderPath}/`;

  //         const copyItemList = copiedPaths.map((item) => {
  //           const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
  //           const fileName = cleanCurrentName.split("/").pop(); // Extract filename

  //           return {
  //             currentName: item.currentName,
  //             newName: `${targetFolderPath}${fileName}${
  //               item.currentName.endsWith("/") ? "/" : ""
  //             }`,
  //           };
  //         });

  //         console.log("Constructed copyItemList:", copyItemList);

  //         CopyService({ copyItemList }, accessToken)
  //           .then((result) => {
  //             if (result) {
  //               console.log("Files pasted successfully");
  //               toast.success("Files pasted successfully!", {
  //                 position: "top-center",
  //                 autoClose: 3000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 closeButton: false,
  //                 style: { color: "green", backgroundColor: "#f4f4f4" },
  //               });
  //             } else {
  //               console.log("Paste failed");
  //               toast.error("Paste failed!", {
  //                 position: "top-center",
  //                 autoClose: 2000,
  //                 hideProgressBar: true,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 style: { color: "#fff", backgroundColor: "#dc3545" },
  //               });
  //             }
  //           })
  //           .catch((error) => console.error("Error during paste:", error))
  //           .finally(() => {
  //             setCopiedPaths([]); // Clear copiedPaths after paste
  //             setOperationType(""); // Reset operation type
  //           });
  //       }

  //       break;

  //     case "Cut":
  //       console.log(selectedFiles.map((item) => item.name));

  //       // setCutPaths([...selectedFiles]);

  //       const pathsToCut = selectedFiles.map((file) => ({
  //         currentName: file.isFolder
  //           ? file.path.endsWith("/")
  //             ? file.path
  //             : `${file.path}/` // Ensure trailing slash for folders
  //           : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
  //       }));
  //       // setCopiedPaths(pathsToCopy);
  //       setCutPaths(pathsToCut);
  //       console.log("Cut Paths:", pathsToCut);
  //       setOperationType("cut");

  //       setMultipleFileSelect([]);
  //       break;

  //     case "Delete":
  //       if (selectedFiles.length >= 1) {
  //         console.log(selectedFiles.map((item) => item.name));
  //         console.log(`Selected files for delete: ${selectedFiles}`);
  //         DeleteService(selectedFiles, accessToken)
  //           .then((_deleteResult) => {
  //             if (_deleteResult === true) {
  //               setCurrentItems((prev) =>
  //                 prev.filter(
  //                   (currentItem) =>
  //                     !selectedFiles.some(
  //                       (selectedItem) =>
  //                         selectedItem["path"] === currentItem["path"]
  //                     )
  //                 )
  //               );

  //               setFiles((prev) =>
  //                 prev.filter(
  //                   (currentItem) =>
  //                     !selectedFiles.some((selectedItem) =>
  //                       selectedItem.path.startsWith("/")
  //                         ? selectedItem.path.slice(1)
  //                         : selectedItem.path === currentItem["name"]
  //                     )
  //                 )
  //               );

  //               setMultipleFileSelect([]);
  //               messageApi.open({
  //                 type: "success",
  //                 content: "File(s) deleted successfully!",
  //                 duration: 3,
  //               });
  //             } else {
  //               messageApi.open({
  //                 type: "error",
  //                 content: "File(s) not deleted. Please try again!",
  //                 duration: 3,
  //               });
  //             }
  //             console.log(_deleteResult);
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //           });
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleContextMenuClose = () => {
    setContextMenu({ visible: false });
  };

  const handelContextMenuOptionClick = (option, selectedFiles) => {
    console.log(`FILES COMMING INSIDE CONTEXT MENU OPTION CLICK`);
    console.log(selectedFiles);
    console.log(`FILES COMMING INSIDE CONTEXT MENU OPTION CLICK`);

    handleContextMenuClose();

    switch (option) {
      case "Preview":
        if (selectedFiles.length === 1) {
          const fileToPreview = selectedFiles[0];
          const index = currentItems.findIndex(
            (item) => item.name === fileToPreview.name
          );
          handleFilePreviewClick(
            fileToPreview.name,
            fileToPreview.path,
            index,
            currentItems
          );
          setIsFilePreviewOverlayOpen(true);
        }

        setMultipleFileSelect([]);

        break;

      case "Download":
        if (selectedFiles.length >= 1) {
          const processedFiles = selectedFiles.map((file) => {
            if (file.isFolder && !file.path.endsWith("/")) {
              return { ...file, path: `${file.path}/` };
            }
            return {
              ...file,
              path: file.path.startsWith("/") ? file.path.slice(1) : file.path,
            };
          });

          DownloadService(processedFiles, accessToken)
            .then((downloadResult) => {
              if (downloadResult) {
                console.log("Download successful");

                // Create a blob URL from the response
                const blob = new Blob([downloadResult], {
                  type: "application/octet-stream", // Adjust MIME type if necessary
                });
                const downloadUrl = window.URL.createObjectURL(blob);

                // Create a temporary link element to trigger the download
                const link = document.createElement("a");
                link.href = downloadUrl;

                console.log(selectedFiles);

                if (
                  selectedFiles.length === 1 &&
                  selectedFiles[0].isFolder === false
                ) {
                  link.setAttribute("download", selectedFiles[0].name); // Use the first file's name
                } else {
                  link.setAttribute("download", "CITRIXBOX-Downloaded.zip"); // Name it generically if multiple files are downloaded
                }

                document.body.appendChild(link);
                link.click();

                // Clean up by removing the link and revoking the blob URL
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
              } else {
                console.log("Download failed");
              }
            })
            .catch((error) => {
              console.log(`Error during download:`, error);
            });
        }

        setMultipleFileSelect([]);
        break;

      case "Email":
        if (selectedFiles.length >= 1) {
          console.log(selectedFiles.map((item) => item.path));

          setSelectedFilesToEmail(selectedFiles);
          console.log(`selected files for email: ${selectedFiles}`);
          setIsEmailOverlayOpen(true);
          setMultipleFileSelect([]);
        }
        break;

      case "Rename":
        console.log(selectedFiles.map((item) => item.name));

        if (selectedFiles.length === 1) {
          setFileToRename(selectedFiles[0]);
          setIsRenameDialogOpen(true); // Open the rename dialog

          setMultipleFileSelect([]);
        }
        break;

      case "Copy":
        console.log(selectedFiles);
        const pathsToCopy = selectedFiles.map((file) => ({
          currentName: file.isFolder
            ? file.path.endsWith("/")
              ? file.path
              : `${file.path}/` // Ensure trailing slash for folders
            : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
        }));
        setCopiedPaths(pathsToCopy);
        console.log("Copied Paths:", pathsToCopy);
        setOperationType("copy");
        setMultipleFileSelect([]);
        break;

      case "Paste":
        console.log(`CURRENT FOLDER PATH`);
        console.log(currentFolderPath);

        // Check if operation type is cut
        if (operationType === "cut" && cutPaths.length > 0) {
          const targetFolderPath = currentFolderPath.endsWith("/")
            ? currentFolderPath
            : `${currentFolderPath}/`;

          const cutItemList = cutPaths.map((item) => {
            const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
            const fileName = cleanCurrentName.split("/").pop(); // Extract filename

            return {
              currentName: item.currentName,
              newName: `${targetFolderPath}${fileName}${
                item.currentName.endsWith("/") ? "/" : ""
              }`,
            };
          });

          console.log("Constructed cutItemList:", cutItemList);

          CutService({ cutItemList }, accessToken, selectedFiles)
            .then((result) => {
              if (result) {
                console.log("Files moved successfully");
                toast.success("Files moved successfully!", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  closeButton: false,
                  style: { color: "green", backgroundColor: "#f4f4f4" },
                });
              } else {
                console.log("Move failed");
                toast.error("Move failed!", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  style: { color: "#fff", backgroundColor: "#dc3545" },
                });
              }
            })
            .catch((error) => console.error("Error during move:", error))
            .finally(() => {
              setCutPaths([]); // Clear cutPaths after paste
              setOperationType(""); // Reset operation type
              setMultipleFileSelect([]);
            });
        }

        // Check if operation type is copy
        else if (operationType === "copy" && copiedPaths.length > 0) {
          const targetFolderPath = currentFolderPath.endsWith("/")
            ? currentFolderPath
            : `${currentFolderPath}/`;

          const copyItemList = copiedPaths.map((item) => {
            const cleanCurrentName = item.currentName.replace(/\/$/, ""); // Clean trailing slash if any
            const fileName = cleanCurrentName.split("/").pop(); // Extract filename

            return {
              currentName: item.currentName,
              newName: `${targetFolderPath}${fileName}${
                item.currentName.endsWith("/") ? "/" : ""
              }`,
            };
          });

          console.log("Constructed copyItemList:", copyItemList);

          CopyService({ copyItemList }, accessToken, selectedFiles)
            .then((result) => {
              if (result) {
                console.log("Files pasted successfully");
                toast.success("Files pasted successfully!", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  closeButton: false,
                  style: { color: "green", backgroundColor: "#f4f4f4" },
                });
              } else {
                console.log("Paste failed");
                toast.error("Paste failed!", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  style: { color: "#fff", backgroundColor: "#dc3545" },
                });
              }
            })
            .catch((error) => console.error("Error during paste:", error))
            .finally(() => {
              setCopiedPaths([]); // Clear copiedPaths after paste
              setOperationType(""); // Reset operation type
              setMultipleFileSelect([]);
            });
        }

        break;

      case "Cut":
        console.log(selectedFiles.map((item) => item.name));

        // setCutPaths([...selectedFiles]);

        const pathsToCut = selectedFiles.map((file) => ({
          currentName: file.isFolder
            ? file.path.endsWith("/")
              ? file.path
              : `${file.path}/` // Ensure trailing slash for folders
            : file.path.replace(/\/$/, ""), // Ensure no trailing slash for files
        }));
        // setCopiedPaths(pathsToCopy);
        setCutPaths(pathsToCut);
        console.log("Cut Paths:", pathsToCut);
        setOperationType("cut");

        setMultipleFileSelect([]);
        break;

      case "Delete":
        if (selectedFiles.length >= 1) {
          console.log(selectedFiles.map((item) => item.name));
          console.log(`Selected files for delete: ${selectedFiles}`);
          DeleteService(selectedFiles, accessToken)
            .then((_deleteResult) => {
              if (_deleteResult === true) {
                setCurrentItems((prev) =>
                  prev.filter(
                    (currentItem) =>
                      !selectedFiles.some(
                        (selectedItem) =>
                          selectedItem["path"] === currentItem["path"]
                      )
                  )
                );

                setFiles((prev) =>
                  prev.filter(
                    (currentItem) =>
                      !selectedFiles.some((selectedItem) =>
                        selectedItem.path.startsWith("/")
                          ? selectedItem.path.slice(1)
                          : selectedItem.path === currentItem["name"]
                      )
                  )
                );

                setMultipleFileSelect([]);
                messageApi.open({
                  type: "success",
                  content: "File(s) deleted successfully!",
                  duration: 3,
                });
              } else {
                messageApi.open({
                  type: "error",
                  content: "File(s) not deleted. Please try again!",
                  duration: 3,
                });
              }
              console.log(_deleteResult);
            })
            .catch((error) => {
              console.error(error);
            });
        }
        break;
      default:
        break;
    }
  };

  const handleRenameSubmit = async (newName) => {
    // Early return if no file or folder is selected
    if (!fileToRename) return;

    // Determine if the item to rename is a folder or file
    const isFolder = fileToRename.isFolder;

    // Split the current path to separate the directory from the item name
    const pathParts = fileToRename.name.split("/");
    const oldName = pathParts.pop(); // Extracts the current item name (last part of path)
    const directoryPath = pathParts.join("/"); // Rejoins the rest as the directory path

    // Define the original path including any necessary trailing slash
    let currentNamePath;
    if (isFolder) {
      // For folders, ensure the path ends with "/"
      currentNamePath = fileToRename.name.endsWith("/")
        ? fileToRename.name
        : `${fileToRename.name}/`;
    } else {
      // For files, keep the path as-is
      currentNamePath = fileToRename.name;
    }

    // Define the new path for the renamed item
    let newFilePath;
    if (isFolder) {
      // For folders, construct the new path with a trailing slash
      if (directoryPath) {
        newFilePath = `${directoryPath}/${newName}/`;
      } else {
        newFilePath = `${newName}/`;
      }
    } else {
      // For files, add the file extension to the new name
      const fileExtension = oldName.slice(oldName.lastIndexOf("."));
      if (directoryPath) {
        newFilePath = `${directoryPath}/${newName}${fileExtension}`;
      } else {
        newFilePath = `${newName}${fileExtension}`;
      }
    }

    // Prepare data payload for the rename request
    const renameData = [{ currentName: currentNamePath, newName: newFilePath }];

    // Call the rename service and handle the response
    try {
      const renameResult = await RenameService(renameData, accessToken);
      if (renameResult === true) {
        // Update the UI to show the new name immediately if the rename was successful
        const updateName = (item) => {
          if (item.name === fileToRename.name) {
            let updatedName = newFilePath;

            // Remove the trailing slash for folders when updating the name in the UI
            if (isFolder && updatedName.endsWith("/")) {
              updatedName = updatedName.slice(0, -1); // Remove the trailing slash for folders
            }

            return { ...item, name: updatedName }; // Update the item's name
          }
          return item; // Return unchanged items
        };

        setCurrentItems(currentItems.map(updateName)); // Update current items displayed
        setFiles(files.map(updateName)); // Update all file entries

        // Show success message to the user
        messageApi.open({
          type: "success",
          content: "Rename successful!",
          duration: 3,
        });
      } else {
        // Show error message if the rename failed
        messageApi.open({
          type: "error",
          content: "Rename failed. Try again!",
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
      // Show error message if there was an exception during rename
      messageApi.open({
        type: "error",
        content: "An error occurred during rename!",
        duration: 3,
      });
    }
  };

  console.log(
    `---------------------------CURRENT ITEMS-------------------------`
  );
  console.log(currentItems);

  const handleSort = () => {
    const sortedItems = [...currentItems].sort((a, b) => {
      // If both are folders or both are files, sort by name
      if ((a.isFolder && b.isFolder) || (!a.isFolder && !b.isFolder)) {
        const nameA = extractFileName(a.name).toLowerCase();
        const nameB = extractFileName(b.name).toLowerCase();
        return isAscending
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }

      // Sort folders before files
      return a.isFolder ? 1 : -1;
    });

    setCurrentItems(sortedItems);
    setIsAscending(!isAscending); // Toggle sorting order
  };

  const handleSortByDate = () => {
    const files = currentItems.filter((item) => !item.isFolder);
    const folders = currentItems.filter((item) => item.isFolder);

    const sortedFiles = files.sort((a, b) => {
      const dateA = new Date(a.createdDate); // Convert to Date object
      const dateB = new Date(b.createdDate); // Convert to Date object
      return isAscendingDate ? dateA - dateB : dateB - dateA; // Ascending/descending
    });

    const sortedItems = [...sortedFiles, ...folders];
    setCurrentItems(sortedItems);
    setIsAscendingDate(!isAscendingDate); // Toggle sorting order
  };

  const handleSortBySize = () => {
    const files = currentItems.filter((item) => !item.isFolder); // Get only files
    const folders = currentItems.filter((item) => item.isFolder); // Get only folders

    const sortedFiles = files.sort((a, b) => {
      const sizeA = a.size || 0; // Handle cases where size might be undefined
      const sizeB = b.size || 0;
      return isAscendingSize ? sizeA - sizeB : sizeB - sizeA; // Ascending/descending
    });

    const sortedItems = [...sortedFiles, ...folders]; // Combine sorted files with original folders
    setCurrentItems(sortedItems);
    setIsAscendingSize(!isAscendingSize); // Toggle sorting order
  };

  const handleSortByReceivedDate = () => {
    const files = currentItems.filter((item) => !item.isFolder); // Get only files
    const folders = currentItems.filter((item) => item.isFolder); // Get only folders

    const sortedFiles = files.sort((a, b) => {
      const receivedDateA = new Date(a.receivedDate); // Convert to Date object
      const receivedDateB = new Date(b.receivedDate); // Convert to Date object
      return isAscendingReceivedDate
        ? receivedDateA - receivedDateB
        : receivedDateB - receivedDateA; // Ascending/descending
    });

    const sortedItems = [...sortedFiles, ...folders];
    setCurrentItems(sortedItems);
    setIsAscendingReceivedDate(!isAscendingReceivedDate); // Toggle sorting order
  };

  // Function to handle modal open
  const openCreateFolderModal = () => {
    setIsCreateFolderModalOpen(true);
  };

  // Function to handle modal close
  const closeCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const handleCreateFolderSubmit = async (folderName) => {
    console.log(folderName);

    const trimmedCurrentPath = currentFolderPath.replace(/\/+$/, ""); // Remove trailing slashes
    let newFolderName;

    if (trimmedCurrentPath === "" || trimmedCurrentPath === "Home") {
      newFolderName = folderName; // Root or Home folder
    } else {
      newFolderName = `${trimmedCurrentPath}/${folderName}`; // Append folder name to current path
    }

    console.log(`Creating folder with name: ${newFolderName}`);

    const folderData = [{ newFolderName }];

    try {
      const createFolderResult = await CreateFolderService(
        folderData,
        accessToken
      );

      if (createFolderResult) {
        // Update the current items with the new folder
        const updatedItems = [
          ...currentItems,
          { name: newFolderName, isFolder: true, path: newFolderName }, // Ensure name and path are correctly set
        ];

        setCurrentItems(updatedItems);
        messageApi.open({
          type: "success",
          content: "Folder created successfully!",
          duration: 3,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Folder creation failed. Please try again!",
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
      messageApi.open({
        type: "error",
        content: "An error occurred while creating the folder!",
        duration: 3,
      });
    }
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    return fileIcons[extension] || fileIcons.default;
  };

  const handleContainerRightClick = (event) => {
    event.preventDefault();
    if (!event.target.closest(".details-Row")) {
      const options = ["Paste"];
      setContextMenu({ visible: true, x: -9999, y: -9999, options });
      setTimeout(() => {
        const contextMenu = document.querySelector(".context-menu");
        if (contextMenu) {
          const contextMenuRect = contextMenu.getBoundingClientRect();
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const padding = 30;
          let x = event.clientX;
          let y = event.clientY;
          if (x + contextMenuRect.width + padding > windowWidth) {
            x = windowWidth - contextMenuRect.width - padding;
          }
          if (x < padding) {
            x = padding;
          }
          if (y + contextMenuRect.height + padding > windowHeight) {
            y = windowHeight - contextMenuRect.height - padding;
          }
          if (y < padding) {
            y = padding;
          }
          setContextMenu({ visible: true, x, y, options });
        }
      }, 0);
      setContextMenuIndex(-1);
    }
  };

  const handelRightClick = (event, file) => {
    event.preventDefault();
    event.stopPropagation();
    const isFileSelected = multipleFileSelect.some(
      (item) => item.name === file.name
    );
    let newSelection = [];
    if (!isFileSelected) {
      newSelection = [
        {
          name: file.name,
          size: file.size,
          path: file.path,
          isFolder: file.isFolder,
        },
      ];
      setMultipleFileSelect(newSelection);
    } else {
      newSelection = [...multipleFileSelect];
    }
    let options;
    if (newSelection.length === 1) {
      options = [
        ...(copiedPaths.length > 0 || cutPaths.length > 0 ? ["Paste"] : []),
        "Preview",
        "Rename",
        "Delete",
        "Email",
        "Download",
        "Cut",
        "Copy",
      ];
    } else if (newSelection.length > 1) {
      // options = ["Download", "Email", "Copy", "Cut", "Delete"];
      options = ["Delete", "Email", "Download", "Cut", "Copy"];
    }
    const folderItems = newSelection.filter((f) => f.isFolder === true);
    if (folderItems.length > 0) {
      if (folderItems.length === 1 && newSelection.length === 1) {
        options = [
          ...(copiedPaths.length > 0 || cutPaths.length > 0 ? ["Paste"] : []),
          "Rename",
          "Delete",
          "Download",
          "Cut",
          "Copy",
        ];
      } else if (folderItems.length === newSelection.length) {
        options = ["Delete", "Download", "Cut", "Copy"];
      } else {
        options = ["Delete", "Download", "Cut", "Copy"];
      }
    }
    setContextMenu({ visible: true, x: -9999, y: -9999, options });
    setTimeout(() => {
      const contextMenu = document.querySelector(".context-menu");
      if (contextMenu) {
        const contextMenuRect = contextMenu.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const padding = 30;
        let x = event.clientX;
        let y = event.clientY;
        if (x + contextMenuRect.width + padding > windowWidth) {
          x = windowWidth - contextMenuRect.width - padding;
        }
        if (x < padding) {
          x = padding;
        }
        if (y + contextMenuRect.height + padding > windowHeight) {
          y = windowHeight - contextMenuRect.height - padding;
        }
        if (y < padding) {
          y = padding;
        }
        setContextMenu({ visible: true, x, y, options });
      }
    }, 0);
    setContextMenuIndex(-1);
  };

  const handleFileSearch = (searchResults) => {
    setIsSearchResults(true);
    setCurrentItems(searchResults);
    setMultipleFileSelect([]);
  };

  // -----------------------------------------------------------

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = e.dataTransfer.items;
    const droppedFiles = [];

    // Collect files and folders with asynchronous handling
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const entry = item.webkitGetAsEntry && item.webkitGetAsEntry();

      if (entry) {
        if (entry.isFile) {
          const file = item.getAsFile();
          if (file) {
            droppedFiles.push({
              file,
              name: file.name,
              size: file.size,
              path: currentFolderPath, // Only folder path
            });
            console.log(
              "File found:",
              file.name,
              file.size,
              `${currentFolderPath}${file.name}`
            );
          }
        } else if (entry.isDirectory) {
          const rootFolderName = entry.name;
          await traverseDirectory(
            entry,
            droppedFiles,
            `${currentFolderPath}${rootFolderName}/`
          );
        }
      }
    }

    setFolderFiles(droppedFiles);
    console.log("Files and folders with path:", droppedFiles);

    try {
      const responses = await FilesandFolderDropService(
        droppedFiles,
        accessToken,
        userDetail
      );
      console.log("Service response:", responses);

      // Check if all responses are successful
      const allSuccess = responses.every((res) => res && res.success);

      if (allSuccess) {
        toast.success("Files uploaded successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { color: "green", backgroundColor: "#f4f4f4" },
        });
      } else {
        toast.error("File upload failed. Please try again.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { color: "red", backgroundColor: "#f4f4f4" },
        });
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("File upload failed. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { color: "red", backgroundColor: "#f4f4f4" },
      });
    }
  };

  // Recursive function to traverse directory structure and handle async files
  const traverseDirectory = (dirEntry, filesList, currentPath) => {
    return new Promise((resolve, reject) => {
      const dirReader = dirEntry.createReader();
      const entries = [];

      const readEntries = () => {
        dirReader.readEntries((results) => {
          if (!results.length) {
            // All entries are read
            Promise.all(
              entries.map((entry) =>
                processEntry(entry, filesList, currentPath)
              )
            )
              .then(resolve)
              .catch(reject);
          } else {
            entries.push(...results);
            readEntries();
          }
        });
      };

      const processEntry = (entry, filesList, currentPath) => {
        return new Promise((resolve) => {
          if (entry.isFile) {
            entry.file((file) => {
              filesList.push({
                file,
                path: currentPath, // Folder path only
                name: file.name, // File name only
              });
              console.log("File found:", currentPath, file.name);
              resolve();
            });
          } else if (entry.isDirectory) {
            console.log("Folder found:", currentPath + entry.name);
            traverseDirectory(
              entry,
              filesList,
              `${currentPath}${entry.name}/`
            ).then(resolve);
          }
        });
      };

      readEntries();
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragActive(false);
  };

  return (
    <>
      {messageContextHolder}
      <ToastContainer theme="dark" />
      {isUploadOverlayOpen && (
        <UploadOverlay
          isOpen={isUploadOverlayOpen}
          onClose={handleCloseOverlay}
          folderPath={currentFolderPath} //Pass the selected folderpath
          accessToken={accessToken}
          // onUploadComplete={handleFileOperationComplete}
          // onUploadComplete={handleFolderClick(currentFolderPath)}
          // onUploadComplete={fetchLatestData}
        />
      )}

      {isEmailOverlayOpen && (
        <EmailOverlay
          onClose={closeEmailOverLay}
          selectedFilesToEmail={selectedFilesToEmail}
          isOpen={isEmailOverlayOpen}
        />
      )}

      {userDetail && (
        <Header1
          email={userDetail.email}
          onLogout={handleLogout}
          name={userDetail.name}
          photoURL={userDetail.profilePicture}
          files={files}
          handleFileSearch={handleFileSearch}
        />
      )}
      <div className="home-body1">
        <SideBar1
          folderTree={folderTree}
          onFolderClick={handleFolderClick}
          selectedFolder={selectedFolder}
          onUploadButtonClick={handleUploadButtonClick}
          ContextCloseHandeler={handleContextMenuClose}
          expandedFolders={expandedFolders} // Track expanded folders
          setExpandedFolders={setExpandedFolders} // Update expanded folders
          onCreateFolderClick={openCreateFolderModal}
        />

        <div className="container2">
          {/* <div className="container2-options"> */}
          {/* {multipleFileSelect.length >= 1 && (
              <div className="container2-options-icon">
                {multipleFileSelect.length <= 1 &&
                  multipleFileSelect.every((item) => !item.isFolder) && (
                    <div
                      className="icon-wrapper"
                      data-label="Preview"
                      onClick={() =>
                        handelContextMenuOptionClick(
                          "Preview",
                          multipleFileSelect
                        )
                      }
                    >
                      <VisibilityOutlinedIcon className="action-icon" />
                    </div>
                  )}
                <div
                  className="icon-wrapper"
                  data-label="Download"
                  onClick={() =>
                    handelContextMenuOptionClick("Download", multipleFileSelect)
                  }
                >
                  <FileDownloadOutlinedIcon className="action-icon" />
                </div>

                {multipleFileSelect.length <= 1 &&
                  multipleFileSelect.every((item) => !item.isFolder) && (
                    <div
                      className="icon-wrapper"
                      data-label="Email"
                      onClick={() =>
                        handelContextMenuOptionClick(
                          "Email",
                          multipleFileSelect
                        )
                      }
                    >
                      <EmailOutlinedIcon className="action-icon" />
                    </div>
                  )}
                <div
                  className="icon-wrapper"
                  data-label="Email"
                  onClick={() =>
                    handelContextMenuOptionClick("Email", multipleFileSelect)
                  }
                >
                  <EmailOutlinedIcon className="action-icon" />
                </div>
                {multipleFileSelect.length <= 1 && (
                  <div
                    className="icon-wrapper"
                    data-label="Rename"
                    onClick={() =>
                      handelContextMenuOptionClick("Rename", multipleFileSelect)
                    }
                  >
                    <DriveFileRenameOutlineOutlinedIcon className="action-icon" />
                  </div>
                )}
                <div className="icon-wrapper" data-label="Copy">
                  <ContentCopyOutlinedIcon className="action-icon copy" />
                </div>
                <div className="icon-wrapper" data-label="Move">
                  <DriveFileMoveOutlinedIcon className="action-icon" />
                </div>
                <div
                  className="icon-wrapper"
                  data-label="Delete"
                  onClick={() =>
                    handelContextMenuOptionClick("Delete", multipleFileSelect)
                  }
                >
                  <DeleteOutlinedIcon className="action-icon" />
                </div>
              </div>
            )} */}
          {/* </div> */}

          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={`container2-body ${dragActive ? `drag-active` : ``}`}
            // onContextMenu={(event) => handleContainerRightClick(event)}
            onContextMenu={(event) => {
              if (copiedPaths.length > 0 || cutPaths.length > 0) {
                handleContainerRightClick(event);
              }
            }}
            // style={{ border: dragActive ? "2px solid blue" : "none" }}
          >
            <div
              className={`details-Row title ${
                dragActive ? `drag-active1` : ``
              }`}
            >
              <SortableHeader
                title="Name"
                onSort={handleSort}
                hoverText="sort by asc & des"
                hoverSpan="10px"
                // icon={<InsertDriveFileIcon />}
                marginLeft="38px"
                dragActive={dragActive}
                headingWidth="40%"
              />
              <SortableHeader
                title="Created Date"
                onSort={handleSortByDate}
                hoverText="sort by date"
                hoverSpan="50px"
                marginLeft="38px"
                dragActive={dragActive}
                headingWidth="20%"
              />
              <SortableHeader
                title="Size"
                onSort={handleSortBySize}
                hoverText="sort by size"
                marginLeft="38px"
                hoverSpan="20px"
                dragActive={dragActive}
                headingWidth="10%"
              />

              {/* <SortableHeader
                title="Received Date"
                onSort={handleSortByReceivedDate}
                hoverText="sort by received date"
                marginLeft="25px"
                hoverSpan="11px"
                dragActive={dragActive}
                headingWidth="10%"
              /> */}
              {isSearchResults && (
                <SortableHeader
                  title="Location"
                  marginLeft="37px"
                  hoverText="sort by location"
                  hoverSpan="11px"
                  headingWidth="30%"
                />
              )}
            </div>
            {currentItems.length == 0 ? <p>No matching results.</p> : <></>}
            {currentItems.map((item, index) => (
              <div
                key={index}
                className={`details-Row ${
                  multipleFileSelect.some((file) => file.name === item.name)
                    ? "selected-file"
                    : ""
                } ${dragActive ? "drag-active3" : ""}`}
                onClick={(event) => handelSingleFileClick(event, item, index)}
                onContextMenu={(event) => handelRightClick(event, item)}
                onDoubleClick={() => {
                  if (item.isFolder) {
                    handleFolderClick(item.path);
                  } else {
                    const index = currentItems.findIndex(
                      (_item) => _item.name === item.name
                    );
                    handleFilePreviewClick(
                      item.name,
                      item.path,
                      index,
                      currentItems
                    );
                  }
                }}
              >
                {item.isFolder ? (
                  <FolderIcon
                    className="folder-icon"
                    style={{
                      width: "30px",
                      color: "fdb900",
                    }}
                  />
                ) : (
                  // <InsertDriveFileIcon
                  //   className="drive-file-icon"
                  //   style={{
                  //     width: "30px",
                  //   }}
                  <img
                    src={getFileIcon(item.name)}
                    alt="file icon"
                    // style={{ width: "30px", backgroundColor: "white" }}
                    className="file-type-icon"
                  />
                  // />
                )}
                <p
                  className="name-field"
                  style={{ cursor: "pointer", paddingLeft: "8px" }}
                >
                  {extractFileName(item.name)}
                </p>
                <p className="created-date-field">
                  {item.isFolder ? "-" : item.createdDate}
                </p>
                <p className="size-field">
                  {item.isFolder ? "-" : `${item.size}KB`}
                </p>
                {/* <p className="received-date-field">
                  {item.isFolder ? "-" : hardcodedReceivedDate}
                </p> */}
                {isSearchResults && (
                  <Tooltip
                    arrow={false}
                    title={`Home/${item.path.substring(
                      0,
                      item.path.lastIndexOf("/")
                    )}`}
                  >
                    <p className="location-field">
                      {item.isFolder
                        ? "-"
                        : item.location
                        ? item.location
                        : "Home"}
                    </p>
                  </Tooltip>
                )}
              </div>
            ))}

            <CreateFolderModal
              isOpen={isCreateFolderModalOpen}
              onClose={closeCreateFolderModal}
              onSubmit={handleCreateFolderSubmit}
              currentFolderPath={currentFolderPath} // Pass folder path if needed
            />

            {/* RenameDialog component */}

            <RenameDialog
              open={isRenameDialogOpen}
              onClose={() => setIsRenameDialogOpen(false)}
              onRename={handleRenameSubmit}
              fileName={
                fileToRename
                  ? fileToRename.isFolder
                    ? fileToRename.name // For folders, pass the full name (no slash at the end)
                    : fileToRename.name.substring(
                        0,
                        fileToRename.name.lastIndexOf(".")
                      ) // For files, pass name without extension
                  : ""
              }
            />
            {isFilePreviewOverlayOpen && (
              <FilePreviewOverlay
                fileName={selectedFile}
                filePath={selectedFilePath}
                fileIndex={currentFileIndex}
                allFiles={currentItems.filter(
                  (item) => item.isFolder === false || item.folder === false
                )}
                onClose={handleFilePreviewClose}
                accessToken={accessToken}
              />
            )}

            {contextMenu.visible && (
              <ul
                className="context-menu"
                style={{
                  top: contextMenu.y,
                  left: contextMenu.x,
                }}
              >
                {contextMenu.options.map((option, idx) => (
                  <React.Fragment key={idx}>
                    <li
                      className={
                        contextMenuIndex === idx ? "highlighted-option" : ""
                      }
                      onClick={() =>
                        handelContextMenuOptionClick(option, multipleFileSelect)
                      }
                      style={{
                        marginTop:
                          option === "Download" ||
                          option === "Cut" ||
                          option === "Delete"
                            ? "10px"
                            : "0",
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        {optionsIcons[option]}
                      </span>
                      {option}
                    </li>
                    {option === "Paste" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Rename" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Delete" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Download" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
                {/* {contextMenu.options.map((option, idx) => (
                  <React.Fragment key={idx}>
                    <li
                      className={
                        contextMenuIndex === idx ? "highlighted-option" : ""
                      }
                      onClick={() =>
                        handelContextMenuOptionClick(option, multipleFileSelect)
                      }
                      style={{
                        marginTop:
                          option === "Download" ||
                          option === "Copy" ||
                          option === "Delete"
                            ? "10px"
                            : "0",
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        {optionsIcons[option]}
                      </span>
                      {option}
                    </li>
                    {option === "Preview" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Rename" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Cut" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))} */}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// const handleKeyDown = (event) => {
//   if (event.key === "ArrowDown") {
//     setSelectedIndex((prevIndex) =>
//       prevIndex < currentItems.length - 1 ? prevIndex + 1 : prevIndex
//     );
//   } else if (event.key === "ArrowUp") {
//     setSelectedIndex((prevIndex) =>
//       prevIndex > 0 ? prevIndex - 1 : prevIndex
//     );
//   }
// };

// Attach keydown event listener
// useEffect(() => {
//   window.addEventListener("keydown", handleKeyDown);
//   return () => {
//     window.removeEventListener("keydown", handleKeyDown);
//   };
// }, [currentItems.length]);

// const updateCurrentItems = (selectedFolder) => {
//   const currentFolderPath = selectedFolder.replace(/\/+$/, ""); // Remove trailing slash

//   // Filter files and folders for the selected folder
//   const filesInFolder = files.filter((item) => {
//     const itemPath = item.name.replace(/\/+$/, "");
//     const parentPath = itemPath.split("/").slice(0, -1).join("/");
//     const itemName = item.name.split("/").pop();

//     return parentPath === currentFolderPath && itemName.includes("."); // File with an extension
//   });

//   const subfoldersInFolder = files.filter((item) => {
//     const itemPath = item.name.replace(/\/+$/, "");
//     const parentPath = itemPath.split("/").slice(0, -1).join("/");
//     const itemName = item.name.split("/").pop();

//     return parentPath === currentFolderPath && !itemName.includes("."); // Subfolder
//   });

//   // Combine files and subfolders
//   const updatedItems = [
//     ...filesInFolder.map((file) => ({
//       name: file.name,
//       isFolder: false,
//       size: file.size,
//       createdDate: file.createdDate,
//       path: file.name,
//     })),
//     ...subfoldersInFolder.map((folder) => ({
//       name: folder.name.split("/").pop(), // Display the folder name, not the full path
//       isFolder: true,
//       path: folder.name,
//     })),
//   ];

//   setCurrentItems(updatedItems); // Update displayed items
// };

// // Handle email button click
// const handelEmailButton = () => {
//   const anyFilesSelected = checkedFiles.length > 0;

//   if (!anyFilesSelected) {
//     messageApi.open({
//       type: "error",
//       content: "Please Select a File To Proceed.",
//       duration: 3,
//     });
//   } else {
//     const filesToSend = currentItems.filter((item) =>
//       checkedFiles.includes(item.name)
//     );
//     setSelectedFilesToEmail(filesToSend);

//     console.log(
//       `FILES TO SEND EMAIL FOR CHECK: ${filesToSend.map((items) => items)}`
//     );
//     setIsEmailOverlayOpen(true);
//   }
// };

// OLD RETURN FUNCTION

// return (
//   <>
//     {messageContextHolder}
//     {isUploadOverlayOpen && (
//       <UploadOverlay
//         isOpen={isUploadOverlayOpen}
//         onClose={handleCloseOverlay}
//         folderPath={currentFolderPath} //Pass the selected folderpath
//         accessToken={accessToken}
//       />
//     )}

//     {isEmailOverlayOpen && (
//       <EmailOverlay
//         onClose={closeEmailOverLay}
//         selectedFilesToEmail={selectedFilesToEmail}
//         isOpen={isEmailOverlayOpen}
//       />
//     )}

//     {userDetail && (
//       <Header1
//         email={userDetail.email}
//         onLogout={handleLogout}
//         name={userDetail.name}
//         photoURL={userDetail.profilePicture}
//       />
//     )}

//     <div className="home-body1">
//       <SideBar1
//         folderTree={folderTree}
//         onFolderClick={handleFolderClick}
//         selectedFolder={selectedFolder}
//         onUploadButtonClick={handleUploadButtonClick}
//         ContextCloseHandeler={handleContextMenuClose}
//         expandedFolders={expandedFolders} // Track expanded folders
//         setExpandedFolders={setExpandedFolders} // Update expanded folders
//       />

//       <div className="container2">
//         <div className="container2-options">
//           <div className="container2-options-icon">
//             {/* <div
//             className="container2-option selectall"
//             onClick={handleSelectAll}
//           >
//             <DoneOutlinedIcon />
//             <span>Select All</span>
//           </div> */}
//             <div className="icon-wrapper" data-label="Move">
//               <DriveFileMoveOutlinedIcon className="action-icon" />
//             </div>
//             <div className="icon-wrapper" data-label="Download">
//               <FileDownloadOutlinedIcon className="action-icon" />
//             </div>
//             <div className="icon-wrapper" data-label="Delete">
//               <DeleteOutlinedIcon className="action-icon" />
//             </div>
//           </div>

//           {/* <div className="container2-option download">
//             <DownloadOutlinedIcon />
//             <span>Download</span>
//           </div>
//           <div className="container2-option rename">
//             <DriveFileRenameOutlineOutlinedIcon />
//             <span>Rename</span>
//           </div> */}
//         </div>

//         <div className="container2-body">
//           <div className="details-Row title">
//             {/* <input
//               type="checkbox"
//               checked={isAllSelected}
//               onChange={handleSelectAll}
//             /> */}

//             {/* <p
//               onClick={handleSort} // Sort when clicked
//               style={{ position: "relative", cursor: "pointer" }}
//             >
//               <b>
//                 Name <ArrowDownwardOutlinedIcon />
//               </b>
//             </p> */}
//             {/*
//             <p
//               onMouseEnter={() => setIsSortingHovered(true)} // Show sort text on hover
//               onMouseLeave={() => setIsSortingHovered(false)} // Hide sort text when not hovering
//               onClick={handleSort} // Sort when clicked
//               style={{ position: "relative", cursor: "pointer" }} // Add cursor and positioning
//             >
//               <b>Name</b>
//               {isSortingHovered && (
//                 <span
//                   style={{
//                     fontSize: "0.8em",
//                     color: "white",
//                     padding: "5px 7px",
//                     borderRadius: "5px",
//                     position: "absolute",
//                     left: "10px",
//                     top: "100%",
//                     whiteSpace: "nowrap",
//                     backgroundColor: "#333",
//                   }}
//                 >
//                   sort by asc & des
//                 </span>
//               )}
//             </p> */}

//             {/* <p className="cr-date">
//               <b>Created Date</b>
//             </p> */}
//             {/* <p
//               onMouseEnter={() => setIsDateHovered(true)} // Show sort text on hover
//               onMouseLeave={() => setIsDateHovered(false)} // Hide sort text when not hovering
//               onClick={handleSortByDate} // Sort when clicked
//               style={{ position: "relative", cursor: "pointer" }} // Add cursor and positioning
//             >
//               <b>Created Date</b>
//               {isDateHovered && (
//                 <span
//                   style={{
//                     fontSize: "0.8em",
//                     color: "white",
//                     position: "absolute",
//                     left: "30px",
//                     top: "100%",
//                     whiteSpace: "nowrap",
//                     padding: "5px 7px",
//                     borderRadius: "5px",
//                     backgroundColor: "#333",
//                   }}
//                 >
//                   sort by date
//                 </span>
//               )}
//             </p> */}
//             <SortableHeader
//               title="Name"
//               onSort={handleSort}
//               hoverText="sort by asc & des"
//             />
//             <SortableHeader
//               title="Created Date"
//               onSort={handleSortByDate}
//               hoverText="sort by date"
//             />
//             <SortableHeader
//               title="Size"
//               onSort={handleSortBySize}
//               hoverText="sort by size"
//             />
//             {/* <p
//               className="size"
//               onMouseEnter={() => setIsSizeHovered(true)} // Show sort text on hover
//               onMouseLeave={() => setIsSizeHovered(false)} // Hide sort text when not hovering
//               onClick={handleSortBySize} // Sort by size when clicked
//               style={{ position: "relative", cursor: "pointer" }} // Add cursor and positioning
//             >
//               <b>Size</b>
//               {isSizeHovered && (
//                 <span
//                   style={{
//                     fontSize: "0.8em",
//                     color: "white",
//                     position: "absolute",
//                     left: "10px",
//                     top: "100%",
//                     whiteSpace: "nowrap",
//                     padding: "5px 7px",
//                     borderRadius: "5px",
//                     backgroundColor: "#333",
//                   }}
//                 >
//                   sort by size
//                 </span>
//               )}
//             </p> */}

//             <SortableHeader
//               title="Received Date"
//               onSort={handleSortByReceivedDate}
//               hoverText="sort by received date"
//             />
//           </div>

//           {currentItems.map((item, index) => (
//             <div
//               key={index}
//               className={`details-Row ${
//                 multipleFileSelect.some((file) => file.name === item.name)
//                   ? "selected-file"
//                   : ""
//               }`}
//               onClick={(event) => handelSingleFileClick(event, item)}
//               onContextMenu={(event) => handelRightClick(event, item)}
//               onDoubleClick={() => {
//                 if (item.isFolder) {
//                   handleFolderClick(item.path);
//                 }
//               }}
//             >
//               {/* <input
//                 type="checkbox"
//                 checked={checkedFiles.includes(item.name)}
//                 onChange={() => handleCheckboxChange(item.name)}
//               /> */}

//               {item.isFolder ? (
//                 <FolderIcon
//                   className="folder-icon"
//                   style={{
//                     width: "17px",
//                   }}
//                 />
//               ) : (
//                 <InsertDriveFileIcon
//                   className="drive-file-icon"
//                   style={{
//                     width: "15px",
//                   }}
//                 />
//               )}
//               <p style={{ cursor: "pointer" }}>
//                 {extractFileName(item.name)}
//               </p>
//               <p>{item.isFolder ? "-" : item.createdDate}</p>
//               <p>{item.isFolder ? "-" : `${item.size}KB`}</p>
//               <p>{item.isFolder ? "-" : hardcodedReceivedDate}</p>
//             </div>
//           ))}

//           {/* RenameDialog component */}
//           <RenameDialog
//             open={isRenameDialogOpen}
//             onClose={() => setIsRenameDialogOpen(false)}
//             onRename={handleRenameSubmit}
//             fileName={
//               fileToRename
//                 ? fileToRename.name.substring(
//                     0,
//                     fileToRename.name.lastIndexOf(".")
//                   )
//                 : ""
//             }
//           />

//           {isFilePreviewOverlayOpen && (
//             <FilePreviewOverlay
//               fileName={selectedFile}
//               filePath={selectedFilePath}
//               fileIndex={currentFileIndex}
//               allFiles={currentItems}
//               onClose={handleFilePreviewClose}
//             />
//           )}

//           {contextMenu.visible && (
//             <ul
//               className="context-menu"
//               style={{
//                 top: contextMenu.y,
//                 left: contextMenu.x,
//               }}
//             >
//               {contextMenu.options.map((option, idx) => (
//                 <React.Fragment key={idx}>
//                   <li
//                     onClick={() =>
//                       handelContextMenuOptionClick(option, multipleFileSelect)
//                     }
//                     style={{
//                       marginTop:
//                         option === "Download" ||
//                         option === "Copy" ||
//                         option === "Delete"
//                           ? "10px"
//                           : "0",
//                     }}
//                   >
//                     <span style={{ marginRight: "8px" }}>
//                       {optionsIcons[option]}
//                     </span>
//                     {option}
//                   </li>
//                   {option === "Preview" && (
//                     <hr
//                       style={{
//                         border: "none",
//                         borderBottom: "0.5px solid rgb(211, 211, 211)",
//                         margin: "5px 0",
//                       }}
//                     />
//                   )}
//                   {option === "Rename" && (
//                     <hr
//                       style={{
//                         border: "none",
//                         borderBottom: "0.5px solid rgb(211, 211, 211)",
//                         margin: "5px 0",
//                       }}
//                     />
//                   )}
//                   {option === "Move" && (
//                     <hr
//                       style={{
//                         border: "none",
//                         borderBottom: "0.5px solid rgb(211, 211, 211)",
//                         margin: "5px 0",
//                       }}
//                     />
//                   )}
//                 </React.Fragment>
//               ))}
//             </ul>
//           )}
//         </div>
//       </div>
//     </div>
//   </>
// );
// }
