import React, { useState, useRef, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import "../components/EmailTagsInput.css";

const EmailTagsInput = ({ sendEmailsBack, areEmailsValid }) => {
    const [emails, setEmails] = useState([]);
    const refEmailTextBox = useRef(null);
    const [input, setInput] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleInputChange = (e) => {
        setInput(e.target.value.trim());
    };

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ',' || e.key === ';') {
            e.preventDefault();
            validateAddEmail();
        }
    };

    // const handleEmailPaste = (e) => {
    //     console.log('kamalakannan', refEmailTextBox.current.value);
    //     const pastedText = e.clipboardData.getData('Text');
    //     e.clipboardData.setData('text/plain','');
    //     const _emails = pastedText.split(",");
    //     let validEmails = [];
    //     let inValidEmails = [];
    //     _emails.map((item) => {
    //         if(validateEmail(item)){
    //             if(!emails.includes(item))
    //                 validEmails.push(item);
    //         }
    //         else{
    //             inValidEmails.push(item);
    //         }
    //     });
    //     setEmails([...emails, ...validEmails]);
    //     setInput('');
    //     setTimeout(() => {
    //         if (refEmailTextBox.current) {
    //             refEmailTextBox.current.value = '';
    //         }
    //       }, 0);
    // };

    const validateAddEmail = () => {
        //const input = refEmailTextBox.current.value;
        if (validateEmail(input)) {
            setEmailError('');
            areEmailsValid(true);
            addEmail(input);
        } else {
            if ((emails.length === 0 && input.length === 0) ||
                (emails.length > 0 && input.length > 0)) {
                areEmailsValid(false);
                setEmailError("Please provide a valid email");
            }
            else {
                setEmailError('');
                areEmailsValid(true);
            }
        }
    };

    const addEmail = (email) => {
        if (email && !emails.includes(email)) {
            const updatedEmails = [...emails, email];
            setEmails(updatedEmails);
            sendEmailsBack(updatedEmails);   
        }
        setInput('');
    };

    const removeEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    };

    useEffect(() => {
        const setFocusTimer = setTimeout(() => {
            if (refEmailTextBox.current) {
                refEmailTextBox.current.focus();
            }
        }, 500);

        return () => clearTimeout(setFocusTimer);
    }, []);

    return (
        <>
            <div className="email-tags">
                {emails.map((email, index) => (
                    <div key={index} className="email-tag">
                        {email}
                        <AiOutlineClose onClick={() => removeEmail(index)} />
                    </div>
                ))}
                <input
                    type="text"
                    ref={refEmailTextBox}
                    onBlur={validateAddEmail}
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    // onPaste={handleEmailPaste}
                    className="memail-input"
                    placeholder="Email"
                />
            </div>
            <p className="email-error-message error-message">{emailError}</p>
        </>
    );
};

export default EmailTagsInput;