import React, { useEffect, useRef, useState } from "react";

import "../newComponent/SideBar.css";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import UploadOverlay from "../components/UploadOverlay";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

// function Folder({
//   folderName,
//   folderPath,
//   children,
//   onClickFolder,
//   selectedFolder,
//   level = 0, // Indentation level
//   autoOpen = false, // New prop to control auto-opening
// }) {
//   const [isOpen, setIsOpen] = useState(autoOpen);

//   const toggleFolder = () => {
//     setIsOpen(!isOpen);
//     if (onClickFolder) {
//       onClickFolder(folderPath);
//     }
//   };

//   // Determine if the folder name is long (more than 6 characters)
//   const isLongName = folderName.length > 6;

//   // Truncate the folder name if it's long
//   const displayName = isLongName ? `${folderName.slice(0, 6)}...` : folderName;

//   // Automatically open the folder if it's selected
//   React.useEffect(() => {
//     if (selectedFolder && selectedFolder.startsWith(folderPath)) {
//       setIsOpen(true);
//     }
//   }, [selectedFolder]);

//   return (
//     <div className="folder">
//       <div
//         className={`sidebar1-option folder-header ${
//           selectedFolder === folderPath ? "selected" : ""
//         }`}
//         onClick={toggleFolder}
//       >
//         <span
//           style={{
//             paddingLeft: `${level * 15}px`,
//             display: "flex",
//             alignItems: "center",
//             gap: "5px",
//           }}
//         >
//           {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
//           {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
//           <span className={`folder-name ${isLongName ? "long-name" : ""}`}>
//             {displayName}
//           </span>
//         </span>
//       </div>

//       {isOpen && children && (
//         <div className="folder-children">
//           {Object.keys(children).map((childFolder) => (
//             <Folder
//               key={childFolder}
//               folderName={childFolder}
//               folderPath={`${folderPath}/${childFolder}`}
//               children={children[childFolder]}
//               onClickFolder={onClickFolder}
//               selectedFolder={selectedFolder}
//               level={level + 1}
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }

// function SideBar1({
//   folderTree,
//   onFolderClick,
//   selectedFolder,
//   onUploadButtonClick,
//   ContextCloseHandeler,
// }) {
//   const uploadHandeler = () => {
//     ContextCloseHandeler();
//     onUploadButtonClick();
//   };

//   return (
//     <div className="sidebar1">
//       <div className="sidebar1-btn">
//         <button onClick={uploadHandeler}>
//           <CloudUploadOutlinedIcon />
//           <span>Upload</span>
//         </button>
//       </div>
//       <hr className="hr" />

//       <div className="sidebar1-options">
//         <div className="sidebar1-option history">
//           <HistoryOutlinedIcon />
//           <span>History</span>
//         </div>

//         <div className="sidebar1-option recent-file">
//           <FileOpenOutlinedIcon />
//           <span>Recent Files</span>
//         </div>

//         <hr style={{ marginBottom: "10px" }} />

//         <div
//           className={`sidebar1-option ${
//             selectedFolder === "Home" ? "selected" : ""
//           }`}
//           onClick={() => onFolderClick("Home")}
//         >
//           <FolderIcon />
//           <span className="sidebar1-home">Home</span>
//         </div>

//         <div className="folder-tree1">
//           {Object.keys(folderTree).length > 0 ? (
//             Object.keys(folderTree).map((folderName) => (
//               <Folder
//                 key={folderName}
//                 folderName={folderName}
//                 folderPath={folderName}
//                 children={folderTree[folderName]}
//                 onClickFolder={onFolderClick}
//                 selectedFolder={selectedFolder}
//               />
//             ))
//           ) : (
//             <div></div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default SideBar1;

function Folder({
  folderName,
  folderPath,
  children,
  onClickFolder,
  selectedFolder,
  level = 0, // Indentation level
  autoOpen = false, // New prop to control auto-opening
}) {
  const [isOpen, setIsOpen] = useState(autoOpen);

  const toggleFolder = () => {
    setIsOpen(!isOpen);
    if (onClickFolder) {
      onClickFolder(folderPath);
    }
  };

  // Determine if the folder name is long (more than 6 characters)
  const isLongName = folderName.length > 6;

  // Truncate the folder name if it's long
  const displayName = isLongName ? `${folderName.slice(0, 6)}...` : folderName;

  // Automatically open the folder if it's selected
  React.useEffect(() => {
    if (selectedFolder && selectedFolder.startsWith(folderPath)) {
      setIsOpen(true);
    }
  }, [selectedFolder]);

  return (
    <div className="folder">
      <div
        className={`sidebar1-option folder-header ${
          selectedFolder === folderPath ? "selected" : ""
        }`}
        onClick={toggleFolder}
      >
        <span
          style={{
            paddingLeft: `${level * 15}px`,
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
          {isOpen ? <FolderIcon /> : <FolderIcon />}
          <span className={`folder-name ${isLongName ? "long-name" : ""}`}>
            {displayName}
          </span>
        </span>
      </div>

      {isOpen && children && (
        <div className="folder-children">
          {Object.keys(children).map((childFolder) => (
            <Folder
              key={childFolder}
              folderName={childFolder}
              folderPath={`${folderPath}/${childFolder}`}
              children={children[childFolder]}
              onClickFolder={onClickFolder}
              selectedFolder={selectedFolder}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SideBar1({
  folderTree,
  onFolderClick,
  selectedFolder,
  onUploadButtonClick,
  ContextCloseHandeler,
  onCreateFolderClick,
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupRef = useRef(null);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const uploadHandeler = () => {
    ContextCloseHandeler();
    onUploadButtonClick();
    togglePopup();
  };

  const handleCreateFolderClick = () => {
    onCreateFolderClick();
    togglePopup();
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div className="sidebar1">
      <div className="sidebar1-btn">
        {/* <button onClick={uploadHandeler}>
          <CloudUploadOutlinedIcon />
          <span>Upload</span>
        </button> */}
        <button onClick={togglePopup}>
          <CloudUploadOutlinedIcon />
          <span>Upload</span>
        </button>
      </div>
      {/* <hr className="hr" /> */}

      <div className="sidebar1-options">
        <div className="sidebar1-option history">
          <HistoryOutlinedIcon />
          <span>Recent</span>
        </div>

        {/* <div className="sidebar1-option recent-file">
          <FileOpenOutlinedIcon />
          <span>Recent</span>
        </div> */}

        <div className="sidebar1-option recent-file">
          <BookmarkBorderOutlinedIcon />
          <span>Favs</span>
        </div>

        <div className="sidebar1-option recent-file">
          <DeleteOutlineOutlinedIcon />
          <span>Trash</span>
        </div>

        {/* <hr style={{ marginBottom: "10px" }} /> */}

        <div
          className={`sidebar1-option ${
            selectedFolder === "Home" ? "selected" : ""
          }`}
          onClick={() => onFolderClick("Home")}
        >
          <HomeOutlinedIcon />
          <span className="sidebar1-home">Home</span>
        </div>

        <div className="folder-tree1">
          {Object.keys(folderTree).length > 0 ? (
            Object.keys(folderTree).map((folderName) => (
              <Folder
                key={folderName}
                folderName={folderName}
                folderPath={folderName}
                children={folderTree[folderName]}
                onClickFolder={onFolderClick}
                selectedFolder={selectedFolder}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup-menu" ref={popupRef}>
          <ul>
            <li onClick={uploadHandeler}>
              <CloudUploadOutlinedIcon style={{ marginRight: "10px" }} />
              Upload File
            </li>
            <li onClick={handleCreateFolderClick}>
              <FolderIcon style={{ marginRight: "10px" }} />
              Create Folder
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default SideBar1;
