import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import "../screens/EmailScreen.css";
import { auth, db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { RingLoader } from "react-spinners";
import { signOut } from "firebase/auth";
import EmailService from "../services/emailService";
import { useUserDetails } from "../customHook/userDetails";
import EmailTagsInput from "../components/EmailTagsInput";

export default function EmailScreen({ closeOverlay, selectedFilesToEmail }) {
  const [userDetail, setUserDetail] = useState(null);
  const [btnSendLabel, setBtnSendLabel] = useState("Send");
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [emailsValid, setEmailsValid] = useState(false);
  const refEmailBodyTextBox = useRef(null);
  const refSubjectTextBox = useRef(null);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState(
    selectedFilesToEmail || []
  );
  const [dropdownValue, setDropdownValue] = useState("Select");

  //  Email Model
  const [emailName, setEmailName] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  //  Error States
  const [fileError, setFileError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [bodyError, setBodyError] = useState("");
  const [emailSentStatus, setEmailSentStatus] = useState("");
  const [emailResult, setEmailResult] = useState(false);
  const { accessToken } = useUserDetails();

  useEffect(() => {
    if (loading) {
      console.log("Loading state: ", loading);
      return;
    }
    if (!userDetail) {
      console.error("No user is signed in.");
      navigate("/");
    }
  }, [loading, userDetail, navigate]);

  useEffect(() => {
    if (error) {
      console.error("Error state: ", error);
    }
  }, [error]);

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetail();
  }, []);

  useEffect(() => {
    if (subject.length > 0) {
      setSubjectError("");
    }
  }, [subject]);

  useEffect(() => {
    if (body.length > 0) {
      setBodyError("");
    }
  }, [body]);

  const getEmailsName = (emails) => {
    setEmailName(emails.join(","));
  };

  const areEmailsValid = (valid) => {
    setEmailsValid(valid);
  };

  const validateSubject = () => {
    if (subject.trim().length === 0) {
      setSubjectError("Please provide a subject");
      return false;
    } else {
      setSubjectError("");
      return true;
    }
  };

  const validateEmailBody = () => {
    if (body.trim().length === 0) {
      setBodyError("Please provide body");
      return false;
    } else {
      setBodyError("");
      return true;
    }
  };

  //  Function to handel email submission
  const handelSubmit = async (e) => {
    e.preventDefault();
    setFileError("");
    // Check if files are selected
    if (selectedFiles.length === 0) {
      setFileError("Please add files to proceed.");
      return false;
    }

    if (emailsValid === false) {
      return false;
    }

    const emailData = {
      emailName,
      subject,
      body,
      selectedFiles: selectedFiles.map((file) => ({
        name: file.name,
        type: file.type,
        path: file.path,
      })),
    };

    if (validateSubject() === false) {
      return false;
    }
    if (validateEmailBody() === false) {
      return false;
    }

    try {
      // API POST CALL
      console.log("Sending email:", emailData);
      setBtnSendLabel("Sending...");
      setIsSendBtnDisabled(true);
      let _emailResult = await EmailService(emailData, accessToken);
      setEmailResult(_emailResult);
      if (_emailResult == true) {
        setEmailName("");
        setSubject("");
        setBody("");
        setSelectedFiles([]);
        setEmailSentStatus("Email sent successfully!");
        closeOverlay(true);
      } else {
        setEmailSentStatus("Email not sent. Please try again.");
      }
    } catch (err) {
      console.log("Error sending email:", err);
      setEmailSentStatus("Email not sent. Please try again.");
    }
    setBtnSendLabel("Send");
    setIsSendBtnDisabled(false);
  };

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetail) {
    return <div>Error: No user is signed in.</div>;
  }

  // Function to remove a file
  const handleDeleteFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      navigate("/home");
    }
  };

  return (
    <>
      {/* {userDetail && (
        // <Header
        //   email={userDetail.email}
        //   onLogout={handleLogout}
        //   photoURL={userDetail.profilePicture}
        //   name={userDetail.name}
        // />
      )} */}
      <div className="home-body">
        <div className="email-container">
          <form
            onSubmit={handelSubmit}
            onKeyDown={(e) => e.key === "Enter" && handelSubmit(e)}
          >
            {/* <p className="email-name">Email</p> */}
            <div className="input-group">
              <div className="input-wrapper">
                <EmailTagsInput
                  sendEmailsBack={getEmailsName}
                  areEmailsValid={areEmailsValid}
                />
              </div>
            </div>
            <div className="input-group">
              <div className="input-wrapper">
                <input
                  type="text"
                  placeholder="Subject"
                  className="subject-input"
                  value={subject}
                  maxLength={250}
                  onChange={(e) => setSubject(e.target.value)}
                  ref={refSubjectTextBox}
                  onBlur={validateSubject}
                  required
                />
                {subjectError && (
                  <p className="email-error-message error-message">
                    {subjectError}
                  </p>
                )}
              </div>
            </div>
            <div className="input-group">
              <div className="input-wrapper">
                <textarea
                  placeholder="Body"
                  className="body-input"
                  value={body}
                  // maxLength={250}
                  rows={3}
                  onChange={(e) => setBody(e.target.value)}
                  ref={refEmailBodyTextBox}
                  onBlur={validateEmailBody}
                  required
                />
                {bodyError && (
                  <p className="email-error-message error-message">
                    {bodyError}
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="proceed-button"
              disabled={isSendBtnDisabled}
            >
              {btnSendLabel}
            </button>
          </form>

          {emailSentStatus && (
            <p className={emailResult ? "sucess-message" : "error-message"}>
              {emailSentStatus}
            </p>
          )}
          {fileError && <p className="error-message">{fileError}</p>}
          {selectedFiles.length > 0 ? (
            <div className="file-table custom-scrollbar">
              <table>
                <thead>
                  <tr>
                    <th>Delete</th>
                    <th>File Name</th>
                    <th>Content-Type</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFiles.map((file) => (
                    <tr key={file.name}>
                      <td>
                        <button
                          type="submit"
                          className="delete-button"
                          onClick={() => handleDeleteFile(file.name)}
                        >
                          X
                        </button>
                      </td>
                      <td>{file.name}</td>
                      <td>{file.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !emailResult && (
              <p className="file-message">
                No files uploded. Please add files to proceed.
              </p>
            )
          )}
        </div>
      </div>
    </>
  );
}
