import { AiOutlineDownload } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import axios from "axios";
const READ_API_HOST_URL = process.env.REACT_APP_READ_API_URL;

function FilePreview({ FilePath, accessToken }) {
  const [isFileSupported, setIsFileSupported] = useState(true);
  const [blobUrl, setBlobUrl] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [isTextFile, setIsTextFile] = useState(false);

  const removePrefix = (filePath) => {
    return filePath.startsWith("/") ? filePath.slice(1) : filePath;
  };

  let headers = `Authorization: Bearer {accessToken}`;

  const getFileName = (filePath) => {
    return filePath.split("/").pop().trim();
  }

  const getFileExtension = (filePath) => {
    return filePath.split(".").pop();
  };

  const filePath = `${READ_API_HOST_URL}/getFileContent?name=${removePrefix(
    FilePath
  )}&kk=${Math.random()}`;

  const handleUnsupportedFile = () => {
    setIsFileSupported(false);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = getFileName(FilePath);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const type = getFileExtension(FilePath);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axios.get(filePath, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        if (type == "txt" || type == "log" || response.data.type === 'text/plain') {
          setTextContent(await response.data.text());
          setIsTextFile(true);
        }
        else if (type == "xls" || type == "xlsx"){
          handleUnsupportedFile();
        }
        else {
          setIsTextFile(false);
        }
        const blob = new Blob([response.data], { type: response.data.type });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      }
      catch (err) {
        handleUnsupportedFile();
      }
    }

    fetchFile();
  }, [FilePath, accessToken]);

  // Check if FilePath is valid before proceeding
  if (!FilePath) {
    return <div>Error: File path is not defined.</div>;
  }

  if (!blobUrl) {
    return <div>Loading...</div>
  }

  return (
    <>
      {isTextFile === true ? <textarea style={{ width: "100%", height: "100%", backgroundColor: "white", overflowY: "auto", border: "0px", resize: "none" }} disabled value={textContent}></textarea> :
        isFileSupported && (
          <FileViewer
            fileType={type}
            filePath={blobUrl}
            style={{ height: "100%" }}
            onError={handleUnsupportedFile}
          />
        )}
      {!isFileSupported && (
        <>
          <div className="unSupportedFile HeaderTitle">
            <div>
              Preview not available for this file.&nbsp;
              <br />
              Please download this file.&nbsp;
              <br />
            </div>
            <div>
              <a href="#" onClick={handleDownload} className="downloadFileIcon">
                <AiOutlineDownload className="downloadFileIcon" />
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FilePreview;